import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowMobileBasket } from 'store/slices/mobileSlice';
import { RootState, AppDispatch } from 'store/store';
import { ServiceType } from 'store/slices/selectedServices';

import { Row, Col, Badge } from 'antd';

import mueLogo from 'assets/images/svg/mue_net_logo.svg';
import CartIcon from 'assets/images/svg/cart_icon.svg';

import style from './style.module.css';

const PHONE_NUMBER = '02566-26 92 96';
const SUBJECT = 'Fragen Online-Vertragsabschluss';
const EMAIL = 'info@muenet.net';

const SideBar = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();

  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const handleOpenBasket = () => {
    dispatch(setShowMobileBasket(true));
  };

  return (
    <div className={style.sidebar_container}>
      <Row className={style.logo_icon_wrapper}>
        <Col sm={12} md={12} lg={24} xl={24} xxl={24}>
          <img src={mueLogo} alt="muenet_logo" className={style.logo} />
        </Col>

        <Col
          sm={12}
          md={12}
          lg={0}
          xl={0}
          xxl={0}
          className={style.cart_wrapper}
        >
          <Badge count={servicesData?.length} color="#FFED00">
            <img
              src={CartIcon}
              alt="cart_icon"
              className={style.cart_icon}
              onClick={handleOpenBasket}
            />
          </Badge>
        </Col>
      </Row>

      <div className={style.sidebar_text_container}>
        <div className={style.sidebar_contact_container}>
          <p>Bei Fragen oder Problemen kontaktieren Sie uns gerne:</p>
          <p>
            E-Mail: <a href={`mailto:${EMAIL}?subject=${SUBJECT}`}>{EMAIL}</a>
          </p>
          <p>
            Telefon: <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </p>
        </div>
        <div className={style.sidebar_contact_container}>
          <p className={style.bottom_text}>MUENET GmbH & Co. KG</p>
          <p className={style.bottom_text}>Rekener Str. 7</p>
          <p className={style.bottom_text}>48653 Coesfeld</p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
