import { useSelector } from 'react-redux';

import {
  selectedExistingConnection,
  selectedPhoneBook
} from 'store/slices/selectedDataSlice';

import { Row, Col, Divider } from 'antd';

import CheckIcon from 'assets/images/svg/check_icon.svg';

import style from './style.module.css';
import { PhoneBook } from 'types/bundle';
import { EQUAL_TO_CUSTOMER_MESSAGE } from 'constants/Constants';

const TransferringInfo = () => {
  const {
    previousProvider,
    currentConnectionOwner,
    existingPhoneNumber,
    portMyCurrentPhoneNumber,
    cancelMyCurrentPlan
  } = useSelector(selectedExistingConnection);

  const phoneBookData: PhoneBook = useSelector(selectedPhoneBook);

  const connectionOwnerCompany = currentConnectionOwner.company;
  const connectionOwnerPersonalData = `${currentConnectionOwner?.title} ${currentConnectionOwner?.name} ${currentConnectionOwner?.lastName}`;

  return (
    <>
      {(portMyCurrentPhoneNumber || cancelMyCurrentPlan) && (
        <>
          <Row>
            <Row className="margin_bottom_l" gutter={[0, 24]}>
              {cancelMyCurrentPlan && (
                <Col xs={24} xl={10}>
                  <Row>
                    <Col span={2}>
                      <img src={CheckIcon} alt="check_icon" />
                    </Col>
                    <Col span={22}>
                      <span className={style.transfer_text}>
                        Ich beauftrage MUENET mit der Kündigung meiner
                        bestehenden Telefonverbindung.
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
              {portMyCurrentPhoneNumber && (
                <Col xs={24} xl={{ span: 10, offset: 2 }}>
                  <Row>
                    <Col span={2}>
                      <img src={CheckIcon} alt="check_icon" />
                    </Col>

                    <Col span={22}>
                      <span className={style.transfer_text}>
                        Ich wünsche die Übernahme meiner alten Rufnummer zu
                        MUENET.
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

            <Col span={12}>
              <Row gutter={[0, 4]} className="margin_bottom_l">
                <Col span={24} className={style.info_title}>
                  Bisheriger Anbieter
                </Col>
                <Col span={24} className={style.info_text}>
                  {previousProvider}
                </Col>
              </Row>

              <Row gutter={[0, 4]}>
                <Col span={24} className={style.info_title}>
                  Bestehender Vertragspartner
                </Col>
                {currentConnectionOwner.equalToCustomer ? (
                  <Col span={24} className={style.info_text}>
                    {EQUAL_TO_CUSTOMER_MESSAGE}
                  </Col>
                ) : (
                  <>
                    {connectionOwnerCompany && (
                      <Col span={24} className={style.info_text}>
                        {connectionOwnerCompany}
                      </Col>
                    )}
                    {connectionOwnerPersonalData && (
                      <Col span={24} className={style.info_text}>
                        {connectionOwnerPersonalData}
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Col>

            <Col span={12} className="margin_bottom_l">
              <Row gutter={[0, 8]}>
                <Col span={24} className={style.info_title}>
                  Bestehende Telefonnummern
                </Col>
                <Row gutter={[0, 8]}>
                  {existingPhoneNumber.map(
                    (phone, index) =>
                      phone.phoneNumber && (
                        <Col span={24} key={index} className={style.info_text}>
                          {`${phone.countryCode} ${phone.areaCode} ${phone.phoneNumber}`}
                        </Col>
                      )
                  )}
                </Row>
              </Row>
            </Col>
          </Row>

          <Divider />
        </>
      )}
      <Col span={24} className={style.phone_book_selection}>
        <span className={`${style.transfer_text}`}>
          Folgenden Telefonnummern sollen einen Telefonbuch-Eintrag erhalten:
        </span>
        <p className={`${style.info_title} ${style.phonebook_option}`}>
          {phoneBookData.phoneBookNumberEntryOption === 1
            ? 'Alle Nummern in das Telefonbuch aufnehmen'
            : phoneBookData.phoneBookNumberEntryOption === 2
              ? 'Nur meine erste Telefonnummer in das Telefonbuch aufnehmen'
              : 'Kein Eintrag im Telefonbuch'}
        </p>
      </Col>

      {Object.entries(phoneBookData).some(
        ([key, value]) => value && key !== 'phoneBookNumberEntryOption'
      ) && (
        <Col span={24}>
          <Row
            gutter={[0, 16]}
            style={{ marginBottom: '16px', fontWeight: 600 }}
          >
            {Object.entries(phoneBookData).map(([key, value]) => {
              if (value && key !== 'phoneBookNumberEntryOption') {
                let selectedOptionText = '';
                switch (key) {
                  case 'phoneBookEntry':
                    selectedOptionText = 'Eintrag in Das Telefonbuch';
                    break;
                  case 'phoneBookInverseSearch':
                    selectedOptionText =
                      'Erlaube, dass ich in einer Rückwärtssuche gefunden werden kann';
                    break;
                  case 'phoneBookOnlineEntry':
                    selectedOptionText =
                      'Eintrag in die Online-Variante in Das Telefonbuch';
                    break;
                  case 'phoneBookAddress':
                    selectedOptionText = 'Binde meine Adresse mit ein';
                    break;
                  default:
                    break;
                }
                return (
                  <>
                    <Col xs={2} md={1}>
                      <img src={CheckIcon} alt="check_icon" />
                    </Col>
                    <Col
                      xs={22}
                      md={11}
                      className={style.info_title}
                      style={{ fontWeight: 600 }}
                    >
                      {selectedOptionText}
                    </Col>
                  </>
                );
              }
            })}
          </Row>
        </Col>
      )}
    </>
  );
};

export default TransferringInfo;
