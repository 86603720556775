import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setPersonalInfo,
  selectedPersonalInfo,
  selectedClientAddressData,
  setClientAddressData
} from 'store/slices/selectedDataSlice';
import { validationErrors } from 'store/slices/validationSlice';

import { ClientAddressData, Packages, PersonalInfo } from 'types/bundle';
import { RootState } from 'store/store';

import { Col, Row, DatePicker, Radio, RadioChangeEvent } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import dayjs from 'dayjs';

import style from './style.module.css';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';

const queryParameters = new URLSearchParams(window.location.search);

const PersonalDetails = () => {
  const dispatch = useDispatch();

  const personalInfo = useSelector(selectedPersonalInfo);
  const [userData, setUserData] = useState<PersonalInfo>(personalInfo);
  const street = queryParameters.get('street') || '';
  const houseNumber = queryParameters.get('street_no') || '';
  const houseNumberSuffix = queryParameters.get('street_no_suffix') || '';
  const postcode = queryParameters.get('postcode') || '';
  const location = queryParameters.get('town') || '';

  const isEmailRequired = useSelector(
    (state: RootState) => state.validationErrors.emailRequired
  );

  const clientAddressData: ClientAddressData = useSelector(
    selectedClientAddressData
  );
  const [clientAddress, setClientAddress] =
    useState<ClientAddressData>(clientAddressData);

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const errors = useSelector(validationErrors);

  const dateDefaultValue =
    personalInfo?.birth_date !== ''
      ? dayjs(personalInfo?.birth_date, 'DD.MM.YYYY')
      : '';

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email' || name === 'phone_number') {
      setUserData({
        ...userData,
        [name]: value.replace(/\s+/g, '')
      });

      dispatch(
        setPersonalInfo({
          ...personalInfo,
          [name]: value.replace(/\s+/g, '')
        })
      );
    } else {
      setUserData({
        ...userData,
        [name]: value
      });

      dispatch(
        setPersonalInfo({
          ...personalInfo,
          [name]: value
        })
      );
    }
  };

  const handleOnDateChange = (date: any, dateString: any) => {
    setUserData({
      ...userData,
      birth_date: dateString
    });

    dispatch(
      setPersonalInfo({
        ...personalInfo,
        birth_date: dateString
      })
    );
  };

  const onTitleChange = (event: string) => {
    setUserData({ ...userData, title: event });

    dispatch(setPersonalInfo({ ...personalInfo, title: event }));
  };

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}.${month}.${year}`;
  };

  const datePickerLimit: string[] = [
    formatDate(
      new Date(
        new Date().getFullYear() - 110,
        new Date().getMonth(),
        new Date().getDate()
      )
    ),
    formatDate(
      new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate()
      )
    )
  ];

  useEffect(() => {
    setUserData({
      ...userData,
      street,
      house_number: houseNumber,
      house_suffix: houseNumberSuffix,
      post_code: postcode,
      location
    });

    dispatch(
      setPersonalInfo({
        ...personalInfo,
        street,
        house_number: houseNumber,
        house_suffix: houseNumberSuffix,
        post_code: postcode,
        location
      })
    );
  }, []);

  const [selectedOption, setSelectedOption] = useState<boolean>(
    clientAddressData.equalToCustomer
  );

  const onOwnerChange = (event: RadioChangeEvent) => {
    const { value } = event.target;

    setSelectedOption(value);

    let data = {
      ...clientAddressData,
      equalToCustomer: value
    };

    if (value) {
      data = {
        ...data,
        street: '',
        house_number: '',
        house_suffix: '',
        post_code: '',
        location: ''
      };
    }

    setClientAddress(data);

    dispatch(setClientAddressData(data));
  };

  const onAddressChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClientAddress({
      ...clientAddress,
      [event.target.name]: event.target.value
    });

    dispatch(
      setClientAddressData({
        ...clientAddressData,
        [event.target.name]: event.target.value
      })
    );
  };

  return (
    <div className={style.personal_detail_container}>
      <>
        {currentPackage?.customer_group_firm && (
          <Row className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24}>
              <FloatingLabelInput
                label="Firma*"
                value={userData?.company}
                onChangeHandler={onChangeHandler}
                name="company"
                errorStatus={
                  errors?.personalInfo?.company && userData.company === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.personalInfo?.company && userData.company === ''
                    ? errors?.personalInfo?.company
                    : undefined
                }
              />
            </Col>
          </Row>
        )}
        <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
          <Col span={8} md={4}>
            <FloatingLabelSelect
              label="Anrede*"
              value={userData.title}
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' }
              ]}
              onChangeHandler={onTitleChange}
              errorStatus={
                errors?.personalInfo?.title && userData.title === ''
                  ? 'error'
                  : ''
              }
              errorMessage={
                errors?.personalInfo?.title && userData.title === ''
                  ? errors?.personalInfo?.title
                  : undefined
              }
            />
          </Col>
          <Col span={16} md={8}>
            <FloatingLabelInput
              label="Vorname*"
              value={userData.first_name}
              onChangeHandler={onChangeHandler}
              name="first_name"
              errorStatus={
                errors?.personalInfo?.first_name && userData.first_name === ''
                  ? 'error'
                  : ''
              }
              errorMessage={
                errors?.personalInfo?.first_name && userData.first_name === ''
                  ? errors?.personalInfo?.first_name
                  : null
              }
            />
          </Col>
          <Col span={24} md={12}>
            <FloatingLabelInput
              label="Nachname*"
              value={userData.last_name}
              onChangeHandler={onChangeHandler}
              name="last_name"
              errorStatus={
                errors?.personalInfo?.last_name && userData.last_name === ''
                  ? 'error'
                  : ''
              }
              errorMessage={
                errors?.personalInfo?.last_name && userData.last_name === ''
                  ? errors?.personalInfo?.last_name
                  : null
              }
            />
          </Col>
        </Row>
        <Row className={`margin_bottom_m ${style.no_margin}`}>
          <Col span={24}>
            <FloatingLabelInput
              label={`E-Mail${isEmailRequired ? '*' : ''}`}
              value={userData.email}
              onChangeHandler={onChangeHandler}
              name="email"
              errorStatus={errors?.personalInfo?.email ? 'error' : ''}
              errorMessage={
                errors?.personalInfo?.email ? errors?.personalInfo?.email : null
              }
            />
          </Col>
        </Row>
        <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
          <Col span={24} md={12}>
            <DatePicker
              placeholder="Geburtsdatum"
              onChange={handleOnDateChange}
              format="DD.MM.YYYY"
              name="birth_date"
              allowClear={false}
              disabledDate={(current) => {
                return (
                  current &&
                  (current < dayjs(datePickerLimit[0], 'DD.MM.YYYY') ||
                    current > dayjs(datePickerLimit[1], 'DD.MM.YYYY'))
                );
              }}
              status={
                errors?.personalInfo?.birth_date && dateDefaultValue === ''
                  ? 'error'
                  : ''
              }
              defaultValue={dateDefaultValue}
            />

            <p className="error-message">
              {errors?.personalInfo?.birth_date && dateDefaultValue === ''
                ? 'Erforderlich'
                : ''}
            </p>
          </Col>
          <Col span={24} md={12}>
            <FloatingLabelInput
              type="tel"
              label="Mobiltelefonnummer für Nachfragen*"
              value={userData.phone_number}
              onChangeHandler={onChangeHandler}
              name="phone_number"
              errorStatus={
                errors?.personalInfo?.phone_number &&
                userData.phone_number === ''
                  ? 'error'
                  : ''
              }
              errorMessage={
                errors?.personalInfo?.phone_number &&
                userData.phone_number === ''
                  ? errors?.personalInfo?.phone_number
                  : null
              }
            />
          </Col>
        </Row>

        <Radio.Group
          value={selectedOption}
          onChange={onOwnerChange}
          className="margin_bottom_l"
        >
          <Row
            justify={'start'}
            gutter={[0, 24]}
            className={style.radio_wrapper}
          >
            <Col span={24} xl={6}>
              <Radio value={true}>Installationsanschrift</Radio>
            </Col>
            <Col span={24} xl={12}>
              <Radio value={false}>Sonstige (bitte angeben)</Radio>
            </Col>
          </Row>
        </Radio.Group>

        {!selectedOption && (
          <>
            <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
              <Col span={16} md={12}>
                <FloatingLabelInput
                  label="Straße*"
                  value={clientAddress.street}
                  onChangeHandler={onAddressChangeHandler}
                  name="street"
                  errorStatus={
                    errors?.installationAddressData?.street &&
                    clientAddress.street === ''
                      ? 'error'
                      : ''
                  }
                  errorMessage={
                    errors?.installationAddressData?.street &&
                    clientAddress.street === ''
                      ? errors?.installationAddressData?.street
                      : null
                  }
                />
              </Col>
              <Col span={8} md={6}>
                <FloatingLabelInput
                  label="Hausnummer*"
                  value={clientAddress.house_number}
                  onChangeHandler={onAddressChangeHandler}
                  name="house_number"
                  errorStatus={
                    errors?.installationAddressData?.house_number &&
                    clientAddress.house_number === ''
                      ? 'error'
                      : ''
                  }
                  errorMessage={
                    errors?.installationAddressData?.house_number &&
                    clientAddress.house_number === ''
                      ? (errors?.installationAddressData
                          ?.house_number as string)
                      : null
                  }
                />
              </Col>
              <Col span={24} md={6}>
                <FloatingLabelInput
                  label="Hausnummerzusatz"
                  value={clientAddress.house_suffix}
                  onChangeHandler={onAddressChangeHandler}
                  name="house_suffix"
                />
              </Col>
            </Row>

            <Row gutter={12} className="margin_bottom_m">
              <Col span={10} md={12}>
                <FloatingLabelInput
                  label="Postleitzahl*"
                  value={clientAddress.post_code}
                  onChangeHandler={onAddressChangeHandler}
                  name="post_code"
                  errorStatus={
                    errors?.installationAddressData?.post_code &&
                    clientAddress.post_code === ''
                      ? 'error'
                      : ''
                  }
                  errorMessage={
                    errors?.installationAddressData?.post_code &&
                    clientAddress.post_code === ''
                      ? errors?.installationAddressData?.post_code
                      : null
                  }
                />
              </Col>
              <Col span={14} md={12}>
                <FloatingLabelInput
                  label="Ort*"
                  value={clientAddress.location}
                  onChangeHandler={onAddressChangeHandler}
                  name="location"
                  errorStatus={
                    errors?.installationAddressData?.location &&
                    clientAddress.location === ''
                      ? 'error'
                      : ''
                  }
                  errorMessage={
                    errors?.installationAddressData?.location &&
                    clientAddress.location === ''
                      ? errors?.installationAddressData?.location
                      : null
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </>

      <p className="package_speed">*Erforderliche Felder</p>
    </div>
  );
};

export default PersonalDetails;
