import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GetProp, UploadFile, UploadProps } from 'antd';
import axios from 'axios';
import { API_URL } from '../../api-config';
import { StatefulUploadFile } from 'components/DragAndDropFileInput/interface';

interface ApiState {
  data: any | null;
  currentlyUploadingFileId: string | null;
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  filesToUpload: UploadFile[];
}

interface UploadFilePayload {
  file: UploadFile;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const initialState: ApiState = {
  data: null,
  currentlyUploadingFileId: null,
  status: 'idle',
  error: null,
  filesToUpload: []
};

export const postFileUpload = createAsyncThunk(
  'api/postFile',
  async (payload: UploadFilePayload) => {
    const { file } = payload;
    const formData = new FormData();
    formData.append('file', file as FileType);
    const response = await axios.post(
      `${API_URL}/orders/upload-file`,
      formData
    );
    return response.data;
  }
);

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    resetFileUploadState: () => initialState,
    setCurrentlyUploadingFile: (state, action) => ({
      ...state,
      currentlyUploadingFileId: action.payload
    }),
    setFilesToUpload: (state, action: PayloadAction<StatefulUploadFile[]>) => {
      state.filesToUpload = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postFileUpload.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(
      postFileUpload.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      }
    );

    builder.addCase(postFileUpload.rejected, (state, action) => {
      state.status = 'failed';
      state.error =
        action.error.message ??
        'Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut.';
    });
  }
});

export const {
  resetFileUploadState,
  setCurrentlyUploadingFile,
  setFilesToUpload
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
