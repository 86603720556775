import * as Yup from 'yup';

import { getCheckboxesByGroupId } from './fixture';
import { CheckboxItem } from './interface';

export interface TermsAndConditionsAgreements {
  [key: string]: boolean;
  agbTermsAgreement: boolean;
  confirmationDlAndRevokeProductsAgreement: boolean;
  confirmationSummaryOfServices: boolean;
}

const REQUIRED_MESSAGE = 'Erforderlich';

const generateTermsAndConditionsSchema = (groupId: string): Yup.Schema => {
  const checkboxes: CheckboxItem[] = getCheckboxesByGroupId(groupId);
  const validationSchemaShape: { [key: string]: Yup.BooleanSchema } = {};

  checkboxes.forEach((checkbox) => {
    validationSchemaShape[checkbox.type] = Yup.boolean().test(
      checkbox.type,
      REQUIRED_MESSAGE,
      (value) => value
    );
  });

  return Yup.object().shape(validationSchemaShape);
};

const generateValidationSchema = (groupId: string): Yup.Schema => {
  const termsAndConditionsSchema = generateTermsAndConditionsSchema(groupId);

  return Yup.object({
    termsAndConditionsAgreements: termsAndConditionsSchema
  });
};

export default generateValidationSchema;
