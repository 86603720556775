import { SelectedFileItemProps } from './interface';
import EmptyFileIcon from 'assets/images/svg/icon_file_empty.svg';
import CancelIcon from 'assets/images/svg/icon-cancel.svg';
import CheckIcon from 'assets/images/svg/check_icon.svg';

import style from './style.module.css';
import { Col, Row, Spin } from 'antd';
import { memo, useMemo } from 'react';

const SelectedFileItem = ({ file, handleRemove }: SelectedFileItemProps) => {
  const wrapperClass = useMemo(() => {
    if (file.state === 'succeeded') return style.selected_item_wrapper_uploaded;

    return style.selected_item_wrapper;
  }, [file.state]);

  return (
    <Row align="middle" className={wrapperClass}>
      <Col span={2}>
        <div className={style.icon_wrapper}>
          {file.state === 'succeeded' && (
            <img src={CheckIcon} alt="check_icon" />
          )}
          {['idle', 'failed', 'forRetry'].includes(file.state) && (
            <img src={EmptyFileIcon} alt="empty_file_icon" />
          )}
          {file.state === 'loading' && <Spin />}
        </div>
      </Col>
      <Col span={20} className={style.selected_item_filename}>
        {file.name}
      </Col>
      <Col span={2}>
        <div className={style.icon_wrapper} onClick={handleRemove}>
          <img src={CancelIcon} alt="cancel_icon" />
        </div>
      </Col>
    </Row>
  );
};
export default memo(SelectedFileItem);
