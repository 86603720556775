import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { BundleData } from 'types/bundle';
import { API_URL } from '../../api-config';

interface InitialState {
  data: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

export const getBundleData = createAsyncThunk<
  BundleData[],
  number | string | null
>('bundle/getBundleData', async (region) => {
  const response = await axios.get<any>(`${API_URL}/products/${region}`);
  return response.data;
});

const bundleSlice = createSlice({
  name: 'bundle',
  initialState: {
    data: [],
    status: 'idle',
    error: ''
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBundleData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getBundleData.fulfilled,
        (state, action: PayloadAction<BundleData[]>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(getBundleData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      });
  }
});

export default bundleSlice.reducer;
