import { UploadResponseMessageProps } from './interface';

import { Row, Col } from 'antd';
import ErrorIcon from 'assets/images/svg/error-icon.svg';
import CheckIcon from 'assets/images/svg/icon-check_circle.svg';
import InfoIcon from 'assets/images/svg/info_icon.svg';

import style from './style.module.css';

const UploadResponseMessage = ({
  message,
  variant
}: UploadResponseMessageProps) => {
  return (
    <div
      className={[
        style.upload_message_container,
        variant === 'error'
          ? style.upload_message_error
          : variant === 'success'
            ? style.upload_message_success
            : style.upload_message_warning
      ].join(' ')}
    >
      <Row className={style.full_width_row}>
        <Col span={2}>
          {variant === 'error' ? (
            <img src={ErrorIcon} alt="error-icon" />
          ) : variant === 'success' ? (
            <img src={CheckIcon} alt="check-icon" />
          ) : (
            <img src={InfoIcon} alt="info-icon" />
          )}
        </Col>
        <Col span={22}>
          <span className={style.upload_message}>{message}</span>
        </Col>
      </Row>
    </div>
  );
};

export default UploadResponseMessage;
