import { Row, Col, Button } from 'antd';
import style from './style.module.css';

const NoPackageSelected = () => {
  return (
    <div className={style.no_package_container}>
      <Row className={style.content_wrapper}>
        <Col span={24}>
          <p className={style.no_package_text}>
            Sie müssen das gewünschte Paket auswählen.
          </p>
        </Col>

        <Col span={24}>
          <p className={style.select_on_website_text}>
            Sie können eines auf unserer Website auswählen
          </p>
        </Col>

        <Col xs={24} xl={6}>
          <Button className={style.go_to_website_button}>
            <a href={'https://www.muenet-glasfaser.de/'}>
              Gehen Sie zur Webseite
            </a>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NoPackageSelected;
