import { useState } from 'react';

import { FloatingSelectProps } from './interface';

import { Select } from 'antd';

import style from './style.module.css';

const { Option } = Select;

const FloatingSelect: React.FC<FloatingSelectProps> = ({
  label,
  options,
  value,
  disabled,
  onChangeHandler,
  errorStatus,
  errorMessage,
  showSearch
}: FloatingSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const isFloating = value !== '' || (showSearch && searchValue !== '');

  return (
    <div className={style.floating_label_input}>
      <Select
        options={options}
        className={`${style.custom_select} ${isFloating ? style.labelFloating : ''}`}
        value={value ?? ''}
        disabled={disabled}
        onChange={(event) => onChangeHandler(event)}
        status={errorStatus}
        showSearch={showSearch}
        onSearch={showSearch ? handleSearch : undefined}
        id="floating_label_input"
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      <label
        htmlFor="floating_label_input"
        className={`${style.label} ${isFloating ? style.labelFloating : ''}`}
      >
        {label}
      </label>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default FloatingSelect;
