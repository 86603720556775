import {
  TAX_INCLUDED_MESSAGE,
  TAX_NOT_INCLUDED_MESSAGE
} from 'constants/Constants';
import { ServiceType } from 'store/slices/selectedServices';

export const sortProductsByCategoryId = (
  a: ServiceType,
  b: ServiceType
): number => {
  return a.service.catID - b.service.catID;
};

export const getTaxInfoMessage = (taxIncluded: boolean): string => {
  return taxIncluded ? TAX_INCLUDED_MESSAGE : TAX_NOT_INCLUDED_MESSAGE;
};

export const firstNameLastNameExists = (
  title?: string,
  firstName?: string,
  lastName?: string
): boolean => {
  if (!(title && firstName && lastName)) {
    return false;
  }

  return true;
};
