import { useState } from 'react';

import { Collapse } from 'antd';

import ExistingConnection from './AddConnection/ExistingConnection';
import AdditionalPhoneNumber from './AdditionalPhoneNumber/AdditionalPhoneNumber';

import {
  ExpandIconOpened,
  ExpandIconClosed,
  ExistingConnectionIcon,
  AdditionalPhoneNumberIcon,
  EntryInTelephoneBookIcon
} from '../../../assets/Icons';

import style from './style.module.css';
import EntryPhoneBook from './EntryPhoneBook/EntryPhoneBook';

const ExistingConnectionSection = () => {
  const { Panel } = Collapse;

  const [activeSection, setActiveSection] = useState<string>('1');

  const [showAdditionalPhoneSection, setShowAdditionalPhoneSection] =
    useState<boolean>(true);

  const onChange = (key: string | string[]) => {
    setActiveSection(key[0]);

    window.scrollTo({ top: 20, left: 0, behavior: 'auto' });
  };

  const selectedPackage = new URLSearchParams(window.location.search).get(
    'package'
  );

  return (
    <Collapse
      onChange={onChange}
      accordion
      ghost
      bordered={true}
      expandIcon={({ isActive }) =>
        isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
      }
      expandIconPosition="end"
      activeKey={activeSection}
    >
      <Panel
        header={
          <div className={style.collapse_header_wrapper}>
            <ExistingConnectionIcon />
            <h2>Bestehender Anschluss</h2>
          </div>
        }
        key="1"
      >
        <ExistingConnection />
      </Panel>

      {showAdditionalPhoneSection && selectedPackage !== 'Light' && (
        <Panel
          header={
            <div className={style.collapse_header_wrapper}>
              <AdditionalPhoneNumberIcon />
              <h2>Zusätzliche Telefonnummern und Dienste (Optional)</h2>
            </div>
          }
          key="2"
          forceRender
        >
          <AdditionalPhoneNumber
            setShowAdditionalPhoneSection={setShowAdditionalPhoneSection}
          />
        </Panel>
      )}
      <Panel
        header={
          <div className={style.collapse_header_wrapper}>
            <EntryInTelephoneBookIcon />
            <h2>Eintrag ins Telefonbuch (Optional)</h2>
          </div>
        }
        key="3"
      >
        <EntryPhoneBook />
      </Panel>
    </Collapse>
  );
};

export default ExistingConnectionSection;
