import React from 'react';
import { Row, Col } from 'antd';

import style from './style.module.css';
import { LocationInfoItemProps } from './interface';

const LocationInfoItem: React.FC<LocationInfoItemProps> = ({
  icon,
  text,
  className
}) => (
  <Row className={style.location_item_wrapper}>
    <Col className={`${style.section_icon} ${style.location_icon_wrapper}`}>
      <div className={style.location_icon}>{icon}</div>
    </Col>
    <Col>
      <p className={className || style.address}>{text}</p>
    </Col>
  </Row>
);

export default LocationInfoItem;
