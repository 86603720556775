import { ErrorMessageProps } from './interface';

import ErrorIcon from 'assets/images/svg/error-icon.svg';

import style from './style.module.css';

const ErrorMessage = ({ errorMessage }: ErrorMessageProps) => {
  return (
    <div className={style.error_message_container}>
      <img className={style.img} src={ErrorIcon} alt="error-icon" />
      <span className={style.error_message}>{errorMessage}</span>
    </div>
  );
};

export default ErrorMessage;
