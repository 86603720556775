type ValidationErrors = {
  [key: string]: string;
};

export const getErrorsFromValidation = (errors: any): ValidationErrors => {
  const validationErrors: ValidationErrors = {};
  errors.inner.forEach((error: any) => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
};

export const formatValidationErrors = (validationErrors: ValidationErrors) => {
  const validationObject = {} as any;

  Object.keys(validationErrors).forEach((key: string) => {
    const [prefix, field] = key.split('.');


    if (!field) {
      validationObject[prefix] = validationErrors[key];
    } else {
      validationObject[prefix] = validationObject[prefix] || {};
      validationObject[prefix][field] = validationErrors[key];
    }
  });

  return validationObject;
};
