import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasPortingOption: false
};

const portingOptionSlice = createSlice({
  name: 'portingOption',
  initialState,
  reducers: {
    setHasPortingOption: (state, action) => {
      state.hasPortingOption = action.payload;
    }
  }
});

export const { setHasPortingOption } = portingOptionSlice.actions;
export default portingOptionSlice.reducer;
