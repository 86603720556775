/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store/store';

import {
  CurrentConnectionOwner,
  ExistingConnectionData,
  ExistingPhoneNumber,
  Packages,
  PreviousProvider,
  Products
} from 'types/bundle';

import {
  selectedExistingConnection,
  setExistingConnection,
  setExistingConnectionOwnerOptions,
  setExistingConnectionPhoneNumbers
} from 'store/slices/selectedDataSlice';

import { getProvidersList } from 'store/slices/previousProvidersSlice';

import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Tooltip
} from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import InfoIcon from 'assets/images/svg/info_icon.svg';

import style from './style.module.css';
import { existingConnections } from 'store/slices/validationSlice';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import ErrorMessage from 'components/ErrorMessage';
import {
  ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE,
  PORT_PHONE_NUMBERS_PRODUCT_ID
} from 'constants/Constants';
import { ServiceType } from 'store/slices/selectedServices';

const GERMANY_COUNTRY_CODE = '+49';
const EXISTING_PHONE_NUMBER = {
  countryCode: GERMANY_COUNTRY_CODE,
  areaCode: '',
  phoneNumber: '',
  phoneNumberPrice: 0
};
const INITIAL_EXISTING_PHONE_NUMBERS = Array.from(
  { length: 3 },
  () => EXISTING_PHONE_NUMBER
);
const ADDITIONAL_PHONE_NUMBER_WITH_PRICE = {
  countryCode: GERMANY_COUNTRY_CODE,
  areaCode: '',
  phoneNumber: '',
  phoneNumberPrice: 2,
  monthly_billing: true,
  offer_duration: 0,
  price_standard: 2
};
const EXISTING_OWNER_OPTIONS: CurrentConnectionOwner = {
  equalToCustomer: true,
  title: '',
  name: '',
  lastName: ''
};

const ExistingConnection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedProducts: any = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const existingConnectionData: ExistingConnectionData = useSelector(
    selectedExistingConnection
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const errors = useSelector(existingConnections);

  const [cancelMyCurrentPlan, setCancelMyCurrentPlan] = useState<boolean>(
    existingConnectionData?.cancelMyCurrentPlan
  );

  const [portMyPhoneNumber, setPortMyPhoneNumber] = useState<boolean>(
    existingConnectionData.portMyCurrentPhoneNumber
  );

  const [previousProvider, setPreviousProvider] = useState<string>(
    existingConnectionData.previousProvider
  );

  const [currentConnectionOwner, setCurrentConnectionOwner] =
    useState<CurrentConnectionOwner>(
      existingConnectionData.currentConnectionOwner
    );

  const [currentConnectionOwnerOption, setCurrentConnectionOwnerOption] =
    useState<string | boolean>(
      existingConnectionData.currentConnectionOwner.equalToCustomer
    );

  const [existingPhoneNumberFields, setExistingPhoneNumberFields] = useState<
    ExistingPhoneNumber[]
  >(existingConnectionData.existingPhoneNumber);

  const [providersList, setProvidersList] = useState<any>([]);

  const resetExistingPhoneNumbers = (): void => {
    if (!cancelMyCurrentPlan && !portMyPhoneNumber) {
      setExistingPhoneNumberFields(
        INITIAL_EXISTING_PHONE_NUMBERS as ExistingPhoneNumber[]
      );

      dispatch(
        setExistingConnectionPhoneNumbers(INITIAL_EXISTING_PHONE_NUMBERS)
      );
    }
  };

  const resetCurrentConnectionOwnerFields = () => {
    setCurrentConnectionOwnerOption(EXISTING_OWNER_OPTIONS.equalToCustomer);
    setCurrentConnectionOwner({
      ...EXISTING_OWNER_OPTIONS
    });

    dispatch(setExistingConnectionOwnerOptions(EXISTING_OWNER_OPTIONS));
  };

  const handleCancelCurrentPlan: CheckboxProps['onChange'] = (e) => {
    const { checked } = e.target;

    setCancelMyCurrentPlan(checked);
    !checked && setPreviousProvider('');

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        cancelMyCurrentPlan: checked
      })
    );
  };

  const handlePortMyPhoneNumber: CheckboxProps['onChange'] = (e) => {
    const { checked } = e.target;

    setPortMyPhoneNumber(checked);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        portMyCurrentPhoneNumber: checked
      })
    );
  };

  const handlePreviousProvider = (event: string) => {
    setPreviousProvider(event);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        previousProvider: event
      })
    );
  };

  const handleAddRow = () => {
    const additionalPhoneNumberWithPriceAndAreacode = {
      ...ADDITIONAL_PHONE_NUMBER_WITH_PRICE,
      areaCode: existingPhoneNumberFields[0].areaCode
    };
    setExistingPhoneNumberFields([
      ...existingPhoneNumberFields,
      additionalPhoneNumberWithPriceAndAreacode
    ]);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: [
          ...existingConnectionData.existingPhoneNumber,
          additionalPhoneNumberWithPriceAndAreacode
        ]
      })
    );
  };

  const handleExistingPhoneFieldsChange = (
    index: number,
    key: keyof ExistingPhoneNumber,
    value: string
  ) => {
    const newFormFields: ExistingPhoneNumber[] = [...existingPhoneNumberFields];

    newFormFields[index] = {
      ...newFormFields[index],
      [key]: value.replace(/\D/g, '')
    };

    const sanitizedFormFields = newFormFields.map((field) => ({
      ...field,
      areaCode: field.areaCode.replace(/^0+/, '').replace(/\D/g, ''),
      phoneNumber: field.phoneNumber.replace(/^0+/, '').replace(/\D/g, '')
    }));

    setExistingPhoneNumberFields(newFormFields);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: sanitizedFormFields
      })
    );
  };

  const handleAreaCodeChange = (value: string) => {
    const newFormFields: ExistingPhoneNumber[] = [...existingPhoneNumberFields];

    const updatedAreaCodes = newFormFields.map((phone) => ({
      ...phone,
      areaCode: value.replace(/\D/g, '')
    }));

    const sanitizedAreaCodes = newFormFields.map((phone) => ({
      ...phone,
      areaCode: value.replace(/^0+/, '').replace(/\D/g, ''),
      phoneNumber: phone.phoneNumber.replace(/^0+/, '').replace(/\D/g, '')
    }));

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: sanitizedAreaCodes
      })
    );

    setExistingPhoneNumberFields(updatedAreaCodes);
  };

  const handleOptionChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setCurrentConnectionOwnerOption(value);

    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      equalToCustomer: value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          equalToCustomer: value
        }
      })
    );

    if (value === true) resetCurrentConnectionOwnerFields();
  };

  const handleUserInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      [event.target.name]: event.target.value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          [event.target.name]: event.target.value
        }
      })
    );
  };

  const handleTitleChange = (value: string) => {
    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      title: value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          title: value
        }
      })
    );
  };

  const handleDeletePhoneNumber = (numberIndex: number) => {
    const filteredPhoneNumbers = existingPhoneNumberFields.filter(
      (item, index) => index !== numberIndex
    );

    setExistingPhoneNumberFields(filteredPhoneNumbers);
    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: filteredPhoneNumbers
      })
    );
  };

  const shouldShowContractPartnerAndPhoneNumbersSections =
    cancelMyCurrentPlan || portMyPhoneNumber;

  const isPortingSelected = !!selectedProducts.find(
    (product: ServiceType) =>
      product.service.productID === PORT_PHONE_NUMBERS_PRODUCT_ID
  );

  const hasPortingOption = useSelector(
    (state: RootState) => state.portingOption.hasPortingOption
  );

  useEffect(() => {
    dispatch(getProvidersList()).then((result) => {
      result.payload && setProvidersList(result.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    if (hasPortingOption) {
      setPortMyPhoneNumber(isPortingSelected);
      dispatch(
        setExistingConnection({
          ...existingConnectionData,
          portMyCurrentPhoneNumber: isPortingSelected
        })
      );
    }
  }, [hasPortingOption]);

  useEffect(() => {
    if (!cancelMyCurrentPlan && !portMyPhoneNumber) {
      resetCurrentConnectionOwnerFields();
      resetExistingPhoneNumbers();
    }
  }, [cancelMyCurrentPlan, portMyPhoneNumber]);

  return (
    <div className={style.connection_container}>
      <Row gutter={[24, 24]} className={style.check_options_wrapper}>
        <Col xs={24} lg={12}>
          <Checkbox
            className="custom_style_checkbox"
            checked={cancelMyCurrentPlan}
            onChange={handleCancelCurrentPlan}
          >
            Ich beauftrage MUENET mit der Kündigung meiner bestehenden
            Telefonverbindung.
          </Checkbox>
        </Col>
        <Col xs={24} lg={12}>
          <Tooltip
            title={
              hasPortingOption && !portMyPhoneNumber
                ? 'Sie müssen bei Produkte & Services unter Telefonie die Rufnummernportierung buchen, damit wir ihre alte Rufnummer übernehmen können.'
                : ''
            }
          >
            <Checkbox
              className="custom_style_checkbox"
              checked={portMyPhoneNumber}
              onChange={handlePortMyPhoneNumber}
              disabled={hasPortingOption}
            >
              Ich wünsche die Übernahme meiner alten Rufnummer zu MUENET.
            </Checkbox>
          </Tooltip>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24} sm={12}>
          <FloatingLabelSelect
            label="Bisheriger Anbieter*"
            value={
              cancelMyCurrentPlan || portMyPhoneNumber ? previousProvider : ''
            }
            options={providersList.map((provider: PreviousProvider) => ({
              label: provider.name,
              value: provider.name
            }))}
            onChangeHandler={handlePreviousProvider}
            disabled={!(cancelMyCurrentPlan || portMyPhoneNumber)}
            errorStatus={errors?.previousProvider ? 'error' : ''}
            errorMessage={errors?.previousProvider}
            showSearch
          />
        </Col>
      </Row>

      <div className={style.transfer_warning_wrapper}>
        <img src={InfoIcon} alt="info_icon" />
        <p className={style.transfer_warning_text}>
          Bitte beachten Sie, dass wir nur die Telefonnummern Ihres direkten
          Providers kündigen. Wenn Sie Telekommunikationdienste von Dritten
          beziehen, müssen Sie diese selbst kündigen!
        </p>
      </div>

      {shouldShowContractPartnerAndPhoneNumbersSections && (
        <>
          <p className={style.section_sub_title}>Bestehender Vertragspartner</p>

          <Radio.Group
            onChange={handleOptionChange}
            value={currentConnectionOwnerOption}
          >
            <Row
              justify={'start'}
              gutter={[0, 24]}
              className={style.radio_wrapper}
            >
              <Col span={24} xl={6}>
                <Radio value={true}>Auftraggeber</Radio>
              </Col>
              <Col span={24} xl={12}>
                <Radio value={false}>Sonstige (bitte angeben)</Radio>
              </Col>
            </Row>
          </Radio.Group>

          {currentConnectionOwnerOption === false && (
            <>
              {(errors.currentConnectionOwner?.company ||
                (errors.currentConnectionOwner?.title &&
                  currentConnectionOwner.title === '') ||
                (errors.currentConnectionOwner?.name &&
                  currentConnectionOwner.name === '') ||
                (errors.currentConnectionOwner?.lastName &&
                  currentConnectionOwner.lastName === '')) && (
                <div className={style.error_message_wrapper}>
                  <ErrorMessage
                    errorMessage={ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE}
                  />
                </div>
              )}

              {currentPackage?.customer_group_firm && (
                <Row className={style.connection_owner_company_wrapper}>
                  <Col span={24}>
                    <FloatingLabelInput
                      label="Firma*"
                      value={currentConnectionOwner?.company}
                      onChangeHandler={handleUserInfoChange}
                      name="company"
                      errorStatus={
                        errors?.currentConnectionOwner?.company ? 'error' : ''
                      }
                      errorMessage={errors?.currentConnectionOwner?.company}
                    />
                  </Col>
                </Row>
              )}

              <Row
                gutter={12}
                className={
                  currentPackage?.customer_group_firm
                    ? style.connection_owner_info_wrapper_mb
                    : style.connection_owner_info_wrapper
                }
              >
                <Col span={8} md={4}>
                  <FloatingLabelSelect
                    value={currentConnectionOwner?.title}
                    label="Anrede*"
                    options={[
                      { label: 'Herr', value: 'Herr' },
                      { label: 'Frau', value: 'Frau' }
                    ]}
                    onChangeHandler={handleTitleChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.title ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.title}
                  />
                </Col>
                <Col span={16} md={10}>
                  <FloatingLabelInput
                    name="name"
                    label="Vorname*"
                    value={currentConnectionOwner.name}
                    onChangeHandler={handleUserInfoChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.name ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.name}
                  />
                </Col>
                <Col span={24} md={10}>
                  <FloatingLabelInput
                    name="lastName"
                    label="Nachname*"
                    value={currentConnectionOwner?.lastName}
                    onChangeHandler={handleUserInfoChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.lastName ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.lastName}
                  />
                </Col>
              </Row>
            </>
          )}

          <div className={style.transfer_warning_wrapper}>
            <img src={InfoIcon} alt="info_icon" />
            <p className={style.transfer_warning_text}>
              Die Kontaktdaten des Auftraggebers werden im nächsten Schritt
              unter &quot;Persönliche Informationen&quot; erfasst.
            </p>
          </div>
        </>
      )}

      <>
        {shouldShowContractPartnerAndPhoneNumbersSections && (
          <>
            <p className={style.section_sub_title}>
              Meine bestehenden Telefonummern
            </p>

            <Row gutter={12}>
              <Col xs={8} sm={6} className={style.country_code_sm}>
                <FloatingLabelInput
                  name="countryCode"
                  label="Länder-Code"
                  value={GERMANY_COUNTRY_CODE}
                  disabled
                />
              </Col>
              <Col xs={14} sm={16} className={style.area_code_sm}>
                <FloatingLabelInput
                  name="areaCode"
                  label="Vorwahl"
                  value={existingPhoneNumberFields[0].areaCode}
                  type="number"
                  onChangeHandler={(e: any) => {
                    handleAreaCodeChange(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {existingPhoneNumberFields.map((field, index) => (
              <Row gutter={12} key={index}>
                <Col span={4} className={style.country_code}>
                  <FloatingLabelInput
                    name="countryCode"
                    label="Länder-Code"
                    value={GERMANY_COUNTRY_CODE}
                    onChangeHandler={(e: any) =>
                      handleExistingPhoneFieldsChange(
                        index,
                        'countryCode',
                        e.target.value
                      )
                    }
                    disabled
                  />
                </Col>
                <Col span={4} className={style.area_code}>
                  <FloatingLabelInput
                    name="areaCode"
                    label="Vorwahl"
                    value={field.areaCode}
                    onChangeHandler={(e: any) => {
                      handleAreaCodeChange(e.target.value);
                    }}
                    disabled={index !== 0}
                    errorStatus={
                      index === 0 &&
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.areaCode
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      index === 0 &&
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.areaCode
                    }
                  />
                </Col>
                <Col span={15} sm={10}>
                  <FloatingLabelInput
                    name="phoneNumber"
                    label={`Telefonnummer ${index + 1}`}
                    value={field.phoneNumber}
                    onChangeHandler={(e: any) =>
                      handleExistingPhoneFieldsChange(
                        index,
                        'phoneNumber',
                        e.target.value
                      )
                    }
                    errorStatus={
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.phoneNumber
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.phoneNumber
                    }
                  />
                </Col>
                <Col span={7} sm={4}>
                  <Input
                    name="phoneNumberPrice"
                    value={`€ ${field.phoneNumberPrice.toFixed(2)} /Monat`}
                    disabled
                    className={style.section_input}
                  />
                  <Input
                    name="phoneNumberPrice"
                    value={`€ ${
                      field.phoneNumberPrice === 0
                        ? field.phoneNumberPrice
                        : field.phoneNumberPrice.toFixed(2)
                    } /Monat`}
                    disabled
                    className={style.section_input_sm}
                  />
                </Col>
                {index > 2 && (
                  <Col span={2} className={style.delete_icon_wrapper}>
                    <DeleteOutlined
                      onClick={() => handleDeletePhoneNumber(index)}
                      className={style.delete_icon}
                      size={24}
                    />
                  </Col>
                )}
              </Row>
            ))}
            <Row>
              <Col xs={24} sm={6}>
                <Button
                  type="text"
                  onClick={handleAddRow}
                  className={style.add_phone_number_button}
                >
                  <span className={style.add_phone_number_text}>
                    + Weitere bestehende Telefonnummer hinzufügen
                  </span>
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    </div>
  );
};

export default ExistingConnection;
