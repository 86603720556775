import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Spin from 'antd/es/spin';

import style from './style.module.css';
import { RootState } from 'store/store';
import ErrorMessage from 'components/ErrorMessage';
import { generateFinalSubmitData } from 'components/Steps/utils';
import { Packages } from 'types/bundle';
import { ServiceType } from 'store/slices/selectedServices';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import { API_URL } from '../../../../../api-config';

const OrderSummaryPDF = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedData = useSelector((state: RootState) => state.selectedData);
  const priceState = useSelector((state: RootState) => state.prices);
  const { paymentRequired } = useSelector((state: RootState) => state.iban);
  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const queryParameters = new URLSearchParams(window.location.search);

  const packageType = queryParameters.get('package');
  const kvRegionID = queryParameters.get('project_kv_id');
  const applicantStreet = queryParameters.get('street');
  const applicantTown = queryParameters.get('town');
  const applicantHouseNumber = queryParameters.get('street_no');
  const applicantHouseSuffix = queryParameters.get('street_no_suffix');
  const applicantPostCode = queryParameters.get('postcode');
  const projectArID = queryParameters.get('project_ar_id');
  const addressID = queryParameters.get('ap_id');
  const hashkey = queryParameters.get('hashkey');
  const sessionID = queryParameters.get('session_id');

  const handleGeneratePDFPreviewClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const submitPayload = generateFinalSubmitData(
        selectedData,
        priceState,
        applicantStreet as string,
        applicantTown as string,
        applicantHouseNumber as string,
        applicantHouseSuffix as string,
        applicantPostCode as string,
        kvRegionID as string,
        projectArID as string,
        addressID as string,
        hashkey as string,
        sessionID as string,
        packageType as string,
        paymentRequired as boolean,
        servicesData as ServiceType[],
        currentPackage as Packages
      );

      const response = await axios.post<Blob>(
        `${API_URL}/orders/order-summary-pdf`,
        submitPayload.submitData,
        {
          responseType: 'blob'
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (err) {
      setError(
        'Es ist ein Fehler aufgetreten. Die PDF-Bestellzusammenfassung konnte nicht generiert werden'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className={style.generate_pdf_text}>
        *Klicken Sie{' '}
        <a href="#" onClick={handleGeneratePDFPreviewClick}>
          hier
        </a>
        , um die PDF-Vorschau Ihrer Bestellzusammenfassung anzuzeigen.
      </p>
      {error && (
        <div className={style.error_message_wrapper}>
          <ErrorMessage errorMessage={error} />
        </div>
      )}
      {isLoading && (
        <div className={style.loading_wrapper}>
          <Spin fullscreen tip="Loading" />
        </div>
      )}
    </div>
  );
};

export default OrderSummaryPDF;
