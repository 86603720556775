import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../api-config';

interface PreviousProvider {
  id: string;
  name: string;
}

interface InitialState {
  data: PreviousProvider[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

export const getProvidersList = createAsyncThunk(
  'previousProviders/getPreviousProviders',
  async () => {
    const response = await axios.get<any>(`${API_URL}/previous-providers/`);
    return response.data;
  }
);

const providersListSlice = createSlice({
  name: 'previousProviders',
  initialState: {
    data: [],
    status: 'idle',
    error: null
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getProvidersList.fulfilled,
        (state, action: PayloadAction<PreviousProvider[]>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(getProvidersList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      });
  }
});

export default providersListSlice.reducer;
