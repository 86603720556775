import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Products } from 'types/bundle';

interface InitialState {
  shippingProduct: Products;
}

const initialState = {
  shippingProduct: {}
} as InitialState;

const shippingProductSlice = createSlice({
  name: 'shippingProduct',
  initialState,
  reducers: {
    setMandatoryProducts: (state, action: PayloadAction<Products>) => {
      state.shippingProduct = action.payload;
    }
  }
});

export const { setMandatoryProducts } = shippingProductSlice.actions;

export default shippingProductSlice.reducer;
