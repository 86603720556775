import { Fragment, useEffect, useMemo, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store/store';
import { Categories, Products } from 'types/bundle';

import {
  addShippingPrice,
  removeAdditionalProducts,
  removeShippingPrice,
  ServiceType,
  setSelectedServices
} from 'store/slices/selectedServices';

import {
  errorMessage,
  errorPlace,
  setErrorPlace
} from 'store/slices/errorSlice';

import { Badge, Collapse, Radio, RadioChangeEvent } from 'antd';
import Product from 'components/Product';
import ErrorMessage from 'components/ErrorMessage';
import { SelectNoneProduct } from './utils';

import {
  CheckedIconBlack,
  ExpandIconClosed,
  ExpandIconOpened
} from '../../../assets/Icons';

import GenericIcon from 'assets/images/svg/icon_generic.svg';

import style from './style.module.css';
import { setMandatoryProducts } from 'store/slices/shippingProductSlice';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';
import { setHasPortingOption } from 'store/slices/portingOptionSlice';

const { Panel } = Collapse;

const ProductsAndServices = () => {
  const panelRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const dispatch: AppDispatch = useDispatch();

  const { data } = useSelector((state: RootState) => state.categories);

  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const message = useSelector(errorMessage);
  const showError = useSelector(errorPlace);

  const [activeSection, setActiveSection] = useState<string>('0');

  const categories = data?.categories || [];
  const products = [data?.packages?.[0]?.products_by_category];

  const getCategoryById = (categoryId: number) => {
    return categories.find(
      (category: Categories) => category.id === categoryId
    );
  };

  const categoryProducts = useMemo(() => {
    if (products[0]) {
      const productsArray: Products[] = Object.values(products[0]);

      return productsArray.filter((product: Products, index: number) =>
        categories.some((category: Categories) => category.id === index + 1)
      );
    } else {
      return [];
    }
  }, [categories, products]);

  const combinedProducts = useMemo(() => {
    return categoryProducts.flatMap(
      ({ additional_products, main_products }: any) => {
        const combinedArray: Products[] = [];

        if (additional_products) {
          combinedArray.push(...additional_products);
        }
        if (main_products) {
          combinedArray.push(...main_products);

          if (main_products.length > 1) {
            const category: Categories = getCategoryById(
              main_products?.[0]?.catID
            );
            combinedArray.push(SelectNoneProduct(category.id, category.name));
          }
        }
        return combinedArray;
      }
    );
  }, [categoryProducts]);

  useEffect(() => {
    const hasPortingOption = combinedProducts.some(
      (product) => product.productID === 729
    );

    dispatch(setHasPortingOption(hasPortingOption));
  }, []);

  const getSelectedProductForCategory = (
    categoryProducts: Products[] | null | undefined
  ) => {
    if (!Array.isArray(categoryProducts)) return null;

    return categoryProducts.find(
      (product) =>
        Array.isArray(servicesData) &&
        servicesData.some(
          (selectedProduct) =>
            selectedProduct?.service?.productID === product.productID
        )
    );
  };

  const onChange = (key: string | string[]) => {
    setActiveSection(Array.isArray(key) ? key[0] : key);
    scrollToPanel(Array.isArray(key) ? key[0] : key);
  };

  const handleProductRadioChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    const selectedProduct: Products = combinedProducts?.find(
      (product: Products) => product?.productID === value
    ) as Products;
    let shippingProduct: Products | undefined = undefined;

    if (selectedProduct?.mandatory_products) {
      shippingProduct = combinedProducts?.find(
        (allProducts: Products) =>
          allProducts?.productID === selectedProduct?.mandatory_products[0]
      );
    }

    if (
      selectedProduct?.catID ===
      categories?.find((category: Categories) => category?.name === 'Router')
        ?.id
    ) {
      dispatch(setErrorPlace(''));
    } else if (
      selectedProduct?.catID ===
      categories?.find((category: Categories) => category?.name === 'IPTV')?.id
    ) {
      dispatch(setErrorPlace(''));
    }

    const index = servicesData.findIndex((product: ServiceType) => {
      return product?.service?.catID === selectedProduct?.catID;
    });

    if (index !== -1) {
      const updatedSelectedService = [...servicesData];
      updatedSelectedService.splice(index, 1, {
        service: selectedProduct,
        amount: 1
      });

      dispatch(setSelectedServices(updatedSelectedService));
    } else {
      dispatch(
        setSelectedServices([
          ...servicesData,
          { service: selectedProduct, amount: 1 }
        ])
      );
    }

    dispatch(removeAdditionalProducts());
    dispatch(removeShippingPrice());
    if (shippingProduct) {
      dispatch(addShippingPrice({ service: shippingProduct, amount: 1 }));
    }
  };

  useEffect(() => {
    const shippingProduct = combinedProducts.find(
      (product: Products) => product?.productID === SHIPPING_PRODUCT_ID
    );

    dispatch(setMandatoryProducts(shippingProduct as Products));
  }, [combinedProducts]);

  useEffect(() => {
    if (showError === 'Router') {
      setActiveSection('1');
      window.scrollTo({ top: 20, left: 0, behavior: 'auto' });
    } else if (showError === 'Iptv') {
      setActiveSection('2');
      window.scrollTo({ top: 20, left: 0, behavior: 'auto' });
    }
  }, [showError]);

  const scrollToPanel = (key: string) => {
    setTimeout(() => {
      const panelRef = panelRefs.current[key];
      if (panelRef) {
        const panelTop = panelRef.getBoundingClientRect().top + window.scrollY;
        const offset = 200;
        window.scrollTo({
          top: panelTop - offset,
          behavior: 'smooth'
        });
      }
    }, 300);
  };

  return (
    <Collapse
      onChange={onChange}
      accordion
      ghost
      bordered={true}
      expandIcon={({ isActive }) =>
        isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
      }
      expandIconPosition="end"
      activeKey={activeSection}
    >
      {/************ MAIN PRODUCTS ************/}

      {categories?.map((category: Categories, index: number) => {
        return (
          category?.user_selectable && (
            <Panel
              forceRender={combinedProducts.some(
                (element: Products) =>
                  element?.catID === category?.id && !element?.option
              )}
              header={
                <div className={style.collapse_header_wrapper}>
                  {servicesData.some(
                    (serviceProducts: ServiceType) =>
                      serviceProducts?.service?.catID === category?.id
                  ) ? (
                    <CheckedIconBlack />
                  ) : category?.image ? (
                    <img src={category.image} alt="category_icon" />
                  ) : (
                    <img
                      src={GenericIcon}
                      alt="category_icon"
                      className={style.generic_icon}
                    />
                  )}
                  <h2 className={style.category_title}>{category.name}</h2>
                </div>
              }
              key={`${index}`}
              ref={(el) => (panelRefs.current[`${index}`] = el)}
            >
              <div>
                {showError === 'Router' && category?.name === 'Router' && (
                  <ErrorMessage errorMessage={message} />
                )}

                {showError === 'Iptv' && category?.name === 'IPTV' && (
                  <ErrorMessage errorMessage={message} />
                )}

                {categoryProducts &&
                  categoryProducts.map(
                    (category: any, categoryIndex: number) => {
                      let categoryMainProductsWithNoneProduct =
                        category?.main_products;
                      if (categoryMainProductsWithNoneProduct?.length > 1) {
                        const categoryData: Categories = getCategoryById(
                          categoryMainProductsWithNoneProduct?.[0]?.catID
                        );
                        categoryMainProductsWithNoneProduct = [
                          ...categoryMainProductsWithNoneProduct,
                          SelectNoneProduct(
                            categoryData?.id,
                            categoryData?.name
                          )
                        ];
                      }

                      const selectedProduct = getSelectedProductForCategory(
                        categoryMainProductsWithNoneProduct
                      );

                      return (
                        <div
                          key={categoryIndex}
                          className={style.products_container}
                        >
                          {index === categoryIndex && (
                            <Radio.Group
                              onChange={handleProductRadioChange}
                              value={selectedProduct?.productID}
                            >
                              {categoryMainProductsWithNoneProduct?.map(
                                (prod: Products) => (
                                  <div key={prod.productID}>
                                    {prod?.marker_type === 1 ||
                                    !prod?.option ? (
                                      <Badge.Ribbon
                                        text={
                                          <p
                                            className={
                                              prod?.marker_type === 1
                                                ? style.text_black
                                                : ''
                                            }
                                          >
                                            {prod?.marker_type === 1
                                              ? 'Kundenliebling'
                                              : 'Im Paket enthalten'}
                                          </p>
                                        }
                                        color={
                                          prod?.marker_type === 1
                                            ? '#ffff00'
                                            : '#181716'
                                        }
                                      >
                                        <Product
                                          product={prod}
                                          productSelected={
                                            servicesData.some(
                                              (services: ServiceType) =>
                                                services?.service?.productID ===
                                                prod?.productID
                                            ) || !prod?.option
                                          }
                                          radio={true}
                                        />
                                      </Badge.Ribbon>
                                    ) : (
                                      <Product
                                        product={prod}
                                        productSelected={
                                          servicesData.some(
                                            (services: ServiceType) =>
                                              services?.service?.productID ===
                                              prod?.productID
                                          ) || !prod?.option
                                        }
                                        radio={true}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </Radio.Group>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>

              {/************ ADDITIONAL PRODUCTS ************/}

              {categoryProducts &&
                categoryProducts.map((product: any, categoryIndex: number) => {
                  if (
                    index === categoryIndex &&
                    product?.additional_products?.length > 0
                  ) {
                    const additionalProducts =
                      product?.additional_products.filter(
                        (prod: Products) => prod.productID
                      );

                    const showAdditional = additionalProducts.some(
                      (product: Products) =>
                        product?.depends_on_products?.some((dependency) =>
                          servicesData.some(
                            (serviceProduct: ServiceType) =>
                              dependency === serviceProduct?.service?.productID
                          )
                        )
                    );

                    const productsWithoutDependencies =
                      additionalProducts.filter(
                        (prod: Products) => !prod?.depends_on_products?.length
                      );

                    const productsWithDependencies = additionalProducts.filter(
                      (prod: Products) =>
                        prod?.depends_on_products &&
                        prod?.depends_on_products?.length > 0
                    );

                    if (additionalProducts.length > 0) {
                      return (
                        <Fragment key={categoryIndex}>
                          {showAdditional && (
                            <>
                              <div
                                className={
                                  style.additional_options_title_wrapper
                                }
                              >
                                <p className="package_name">Weitere Optionen</p>
                              </div>

                              {/************ RENDER PRODUCTS WITH DEPENDENCIES ************/}
                              {productsWithDependencies.map(
                                (prod: Products) =>
                                  servicesData.some((product: ServiceType) =>
                                    prod?.depends_on_products?.includes(
                                      product?.service?.productID
                                    )
                                  ) && (
                                    <div key={prod.productID}>
                                      {!prod?.option ? (
                                        <Badge.Ribbon
                                          text={'Im Paket enthalten'}
                                          color="#181716"
                                        >
                                          <Product
                                            product={prod}
                                            productSelected={!prod?.option}
                                            checkbox={true}
                                          />
                                        </Badge.Ribbon>
                                      ) : (
                                        <Product
                                          product={prod}
                                          productSelected={!prod?.option}
                                          checkbox={true}
                                        />
                                      )}
                                    </div>
                                  )
                              )}
                            </>
                          )}

                          {/************ RENDER PRODUCTS WITHOUT DEPENDENCIES ************/}
                          {productsWithoutDependencies.length > 0 && (
                            <>
                              {!showAdditional && (
                                <div
                                  className={
                                    style.additional_options_title_wrapper
                                  }
                                >
                                  <p className="package_name">
                                    Weitere Optionen
                                  </p>
                                </div>
                              )}
                              {productsWithoutDependencies.map(
                                (prod: Products) => (
                                  <div key={prod.productID}>
                                    {!prod?.option ? (
                                      <Badge.Ribbon
                                        text={'Im Paket enthalten'}
                                        color="#181716"
                                      >
                                        <Product
                                          product={prod}
                                          productSelected={!prod?.option}
                                          checkbox={true}
                                        />
                                      </Badge.Ribbon>
                                    ) : (
                                      <Product
                                        product={prod}
                                        productSelected={!prod?.option}
                                        checkbox={true}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </Fragment>
                      );
                    }
                  }
                  return null;
                })}
            </Panel>
          )
        );
      })}
    </Collapse>
  );
};

export default ProductsAndServices;
