import React from 'react';
import { Col, Divider, Row } from 'antd';

import CheckIcon from 'assets/images/svg/check_icon.svg';
import { formatPrice } from '../utils';
import { OrderSummaryLineProps } from './interface';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';
import { Products } from 'types/bundle';

const OrderSummaryLine: React.FC<OrderSummaryLineProps> = ({
  service,
  amount
}) => {
  const getServicePrice = (service: Products, amount: number) => {
    if (!service?.monthly_billing) {
      return `${formatPrice(service?.price_standard * amount)} €`;
    }

    if (service?.price_offer !== undefined && service?.price_offer !== null) {
      return `${formatPrice(service?.price_offer * amount)} € / Monat`;
    }

    return `${formatPrice(service?.price_standard * amount)} € / Monat`;
  };

  return (
    <Row align="middle">
      <Col span={16}>
        <Row>
          <Col xs={0} lg={2}>
            <img src={CheckIcon} alt="check_icon" />
          </Col>
          <Col span={20}>
            {service?.productID !== SHIPPING_PRODUCT_ID ? (
              <p className="package_name">
                {amount > 1 ? `${amount}x ${service?.name}` : service?.name}
              </p>
            ) : (
              <p className="package_name">{service?.name}</p>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row>
          <Col span={24}>
            <p className="package_price">{getServicePrice(service, amount)}</p>
          </Col>

          {service?.offer_duration ? (
            <Col span={24}>
              <p className="shipping_price_label">
                Ab dem {service?.offer_duration + 1}. Monat{' '}
                <b>{formatPrice(service?.price_standard * amount)} €/Monat</b>
              </p>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Divider />
    </Row>
  );
};

export default OrderSummaryLine;
