import { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedServices,
  ServiceType
} from 'store/slices/selectedServices';

import { RootState } from 'store/store';
import { Packages, Products } from 'types/bundle';
import { Props } from './interface';

import { Row, Col, Checkbox, Divider, CheckboxProps } from 'antd';

import style from './style.module.css';
import FloatingSelect from 'components/FloatingLabelSelect/FloatingLabelSelect';
import {
  ADDITIONAL_PHONE_NUMBER_PRODUCT_ID,
  CONVERSATION_CHANNEL_PRODUCT_ID
} from 'constants/Constants';

const AdditionalPhoneNumber = ({ setShowAdditionalPhoneSection }: Props) => {
  const dispatch = useDispatch();

  const categories = useSelector((state: RootState) => state.categories);
  const packages: Packages[] = categories?.data?.packages || [];

  const additionalProductIDs = [ADDITIONAL_PHONE_NUMBER_PRODUCT_ID, 248];
  const conversationProductsIDs = [CONVERSATION_CHANNEL_PRODUCT_ID, 285, 282];

  const servicesData: any = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const currentAdditionalPhoneProduct = servicesData.find(
    (product: ServiceType) =>
      product?.service?.productID === ADDITIONAL_PHONE_NUMBER_PRODUCT_ID
  );

  const additionalPhoneAmount: number = currentAdditionalPhoneProduct
    ? currentAdditionalPhoneProduct.amount
    : 1;

  const currentConversationChannelProduct = servicesData.find(
    (product: ServiceType) =>
      product?.service?.productID === CONVERSATION_CHANNEL_PRODUCT_ID
  );

  const conversationchanelAmount: number = currentConversationChannelProduct
    ? currentConversationChannelProduct.amount
    : 1;

  const categoryProducts = useMemo(() => {
    if (packages[0]) {
      return Object.values(packages[0]?.products_by_category);
    } else {
      return [];
    }
  }, [categories, packages]);

  const combinedProducts = useMemo(() => {
    return categoryProducts.flatMap(
      ({ additional_products, main_products }: any) => {
        const combinedArray: Products[] = [];

        if (additional_products) {
          combinedArray.push(...additional_products);
        }
        if (main_products) {
          combinedArray.push(...main_products);
        }
        return combinedArray;
      }
    );
  }, [categoryProducts]);

  const [additionalPhone, setAdditionalPhone] = useState({
    addPhoneNumber: {},
    numbersAmount: additionalPhoneAmount
  });

  const [conversationChanel, setChanel] = useState({
    addChanel: {},
    chanelAmount: conversationchanelAmount
  });

  const additionalPhoneProduct: Products = combinedProducts.filter(
    (product: Products) => additionalProductIDs.includes(product?.productID)
  )?.[0];

  const newNumberMaxAllowed = Array.from(
    { length: additionalPhoneProduct?.max_amount || 0 },
    (_, index) => ({
      value: index + 1,
      label: `${index + 1}`
    })
  );

  const additionalChannelProduct: Products = combinedProducts.filter(
    (product) => conversationProductsIDs.includes(product.productID)
  )?.[0];

  const newChanelMaxAllowed = Array.from(
    { length: additionalChannelProduct?.max_amount || 0 },
    (_, index) => ({
      value: index + 1,
      label: `${index + 1}`
    })
  );

  const handleAddNumberChange: CheckboxProps['onChange'] = (e) => {
    const { value, checked } = e.target;

    const isProductSelected = servicesData.some(
      (product: ServiceType) => product?.service?.productID === value.productID
    );

    if (isProductSelected) {
      const filteredProducts = servicesData.filter(
        (product: ServiceType) =>
          product?.service?.productID !== value.productID
      );

      dispatch(setSelectedServices(filteredProducts));
    } else {
      dispatch(
        setSelectedServices([...servicesData, { service: value, amount: 1 }])
      );
    }

    if (!checked) {
      setAdditionalPhone({ ...additionalPhone, numbersAmount: 1 });
    }
  };

  const onAmountChange = (value: number, productId: number) => {
    setAdditionalPhone({ ...additionalPhone, numbersAmount: value });

    const updatedServices = servicesData.map((obj: ServiceType) => {
      if (obj.service.productID === productId) {
        return { ...obj, amount: value };
      }
      return obj;
    });

    dispatch(setSelectedServices(updatedServices));
  };

  const handleAddChanel: CheckboxProps['onChange'] = (e) => {
    const { value, checked } = e.target;

    const isProductSelected = servicesData.some(
      (product: ServiceType) => product?.service?.productID === value.productID
    );

    if (isProductSelected) {
      const filteredProducts = servicesData.filter(
        (product: ServiceType) =>
          product?.service?.productID !== value.productID
      );

      dispatch(setSelectedServices(filteredProducts));
    } else {
      dispatch(
        setSelectedServices([
          ...servicesData,
          { service: value, amount: conversationChanel?.chanelAmount }
        ])
      );
    }

    if (!checked) {
      setChanel({ ...conversationChanel, chanelAmount: 1 });
    }
  };

  const onChanelAmountChange = (value: number, productId: number) => {
    setChanel({ ...conversationChanel, chanelAmount: value });

    const updatedServices = servicesData.map((obj: ServiceType) => {
      if (obj.service.productID === productId) {
        return { ...obj, amount: value };
      }
      return obj;
    });

    dispatch(setSelectedServices(updatedServices));
  };

  useEffect(() => {
    if (
      !additionalPhoneProduct?.productID &&
      !additionalChannelProduct?.productID
    ) {
      setShowAdditionalPhoneSection(false);
    }
  }, []);

  return (
    <div className={style.additional_container}>
      {additionalPhoneProduct && additionalPhoneProduct?.productID && (
        <div className="package_card_wrapper">
          <Row gutter={[0, 24]}>
            <Col span={20}>
              <Checkbox
                className="custom_style_checkbox"
                checked={servicesData.some(
                  (services: ServiceType) =>
                    services?.service?.productID ===
                    additionalPhoneProduct?.productID
                )}
                onChange={handleAddNumberChange}
                value={additionalPhoneProduct}
              >
                <p className="package_name">
                  {additionalPhoneProduct?.description.replace(
                    /<\/?[^>]+(>|$)/g,
                    ''
                  )}
                </p>
              </Checkbox>
            </Col>
            <Col span={24} sm={4}>
              <FloatingSelect
                defaultValue={1}
                value={additionalPhone.numbersAmount}
                className={style.phone_numbers_select}
                onChangeHandler={(value) =>
                  onAmountChange(
                    parseInt(value, 10),
                    additionalPhoneProduct?.productID
                  )
                }
                options={newNumberMaxAllowed}
                disabled={
                  !servicesData.some(
                    (services: ServiceType) =>
                      services?.service?.productID ===
                      additionalPhoneProduct?.productID
                  )
                }
                label="Anzahl: "
              />
            </Col>
          </Row>

          {additionalPhoneProduct &&
            additionalPhoneProduct.description_long && (
              <Row>
                <Col
                  span={24}
                  dangerouslySetInnerHTML={{
                    __html: additionalPhoneProduct.description_long
                  }}
                />
              </Row>
            )}

          <Divider />

          <Row justify={'end'}>
            {additionalPhoneProduct?.description_link && (
              <Col span={12}>
                <p className="show_more">Mehr erfahren</p>
              </Col>
            )}
            <Col span={12}>
              <p className="package_price">{`€ ${(
                additionalPhoneProduct?.price_offer ??
                additionalPhoneProduct?.price_standard
              )
                ?.toFixed(2)
                .replace('.', ',')} / Monat`}</p>
            </Col>
          </Row>
        </div>
      )}

      {/******************* Add new conversation channel **********************/}

      {additionalChannelProduct && additionalChannelProduct?.productID && (
        <div className="package_card_wrapper">
          <Row gutter={[0, 24]}>
            <Col span={20}>
              <Checkbox
                className="custom_style_checkbox"
                checked={servicesData.some(
                  (services: ServiceType) =>
                    services?.service?.productID ===
                    additionalChannelProduct?.productID
                )}
                onChange={handleAddChanel}
                value={additionalChannelProduct}
              >
                <p className="package_name">
                  {additionalChannelProduct?.description.replace(
                    /<\/?[^>]+(>|$)/g,
                    ''
                  ) || ''}
                </p>
              </Checkbox>
            </Col>
            <Col span={24} sm={4}>
              <FloatingSelect
                defaultValue={1}
                value={conversationChanel.chanelAmount}
                className={style.phone_numbers_select}
                onChangeHandler={(value) =>
                  onChanelAmountChange(
                    parseInt(value, 10),
                    additionalChannelProduct?.productID
                  )
                }
                options={newChanelMaxAllowed}
                disabled={
                  !servicesData.some(
                    (services: ServiceType) =>
                      services?.service?.productID ===
                      additionalChannelProduct?.productID
                  )
                }
                label="Anzahl: "
              />
            </Col>
          </Row>

          {additionalChannelProduct &&
            additionalChannelProduct.description_long && (
              <Row>
                <Col
                  span={24}
                  dangerouslySetInnerHTML={{
                    __html: additionalChannelProduct.description_long
                  }}
                />
              </Row>
            )}

          <Divider />

          <Row justify={'end'}>
            {additionalChannelProduct?.description_link && (
              <Col span={12}>
                <p className="show_more">Mehr erfahren</p>
              </Col>
            )}

            <Col span={12}>
              <p className="package_price">{`€ ${
                (
                  additionalChannelProduct?.price_offer ??
                  additionalChannelProduct?.price_standard ??
                  0
                )
                  ?.toFixed(2)
                  .replace('.', ',') ?? '0,00'
              } / Monat`}</p>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AdditionalPhoneNumber;
