import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../api-config';
import { CharitiesData } from 'types/bundle';

interface InitialState {
  data: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

export const getCharities: any = createAsyncThunk(
  'charities/getCharities',
  async (region) => {
    const response = await axios.get<CharitiesData>(
      `${API_URL}/donation-organisations/${region}`
    );
    return response.data;
  }
);

const charitiesSlice = createSlice({
  name: 'bundle',
  initialState: {
    data: {},
    status: 'idle',
    error: ''
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCharities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getCharities.fulfilled,
        (state, action: PayloadAction<CharitiesData>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(getCharities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      });
  }
});

export default charitiesSlice.reducer;
