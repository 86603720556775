export const SelectNoneProduct = (catID: number, categoryName: string) => {
  return {
    catID: catID,
    depends_on_products: [],
    description: `<p>Ohne ${categoryName}</p>`,
    description_link: '',
    description_long: '<p></p>',
    group: -1,
    image: '',
    list_price: '0',
    mandatory_products: [],
    marker_text: '<p><br></p>',
    marker_type: -1,
    max_amount: 1,
    monthly_billing: false,
    name: `Ohne ${categoryName} `,
    offer_duration: 0,
    option: true,
    price_list: 0,
    price_offer: 0,
    price_standard: 0,
    productID: 0 - catID
  };
};
