import { Row, Col, Checkbox, Radio, Divider, Tooltip } from 'antd';

import style from './style.module.css';
import { PhoneBook } from 'types/bundle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedPhoneBook,
  setPhoneBook
} from 'store/slices/selectedDataSlice';

import { MoreInfoIcon } from '../../../../assets/Icons';

const EntryPhoneBook = () => {
  const dispatch = useDispatch();

  const phoneBookData: PhoneBook = useSelector(selectedPhoneBook);

  const handleCheckboxChange = (key: keyof PhoneBook, checked: any) => {
    const updatedPhoneBookData = { ...phoneBookData };

    if (key !== 'phoneBookNumberEntryOption') {
      updatedPhoneBookData[key] = checked;
    }

    dispatch(setPhoneBook(updatedPhoneBookData));
  };

  const handleRadioButtonSelect = (selectedOption: number) => {
    const updatedPhoneBookData = { ...phoneBookData };
    const keys = Object.keys(updatedPhoneBookData) as (keyof PhoneBook)[];

    updatedPhoneBookData.phoneBookNumberEntryOption = selectedOption;

    if (selectedOption === 0) {
      keys.forEach((key) => {
        if (key !== 'phoneBookNumberEntryOption') {
          updatedPhoneBookData[key] = false;
        }
      });
    } else {
      updatedPhoneBookData.phoneBookEntry = true;
      updatedPhoneBookData.phoneBookOnlineEntry = true;
    }

    dispatch(setPhoneBook(updatedPhoneBookData));
  };

  const tooltipContent = `
    <div>
    Hier können Sie auswählen, ob Ihre Telefonnummer(n) im Telefonbuch erscheinen sollen. <br>
    Hinweis: Falls Sie wünschen, dass mehrere, aber nicht alle Nummern im Telefonbuch angezeigt werden, können Sie das nach der Aktivierung Ihres Accounts selbst online einstellen.
    <br>
    Wir empfehlen, dass Sie dann hier zunächst "Nur meine erste Telefonnummer in das Telefonbuch aufnehmen" auswählen.
    </div>
    `;

  return (
    <div className="package_card_wrapper">
      <h2 className={style.section_title}>
        <Row>
          <span className={style.section_title}>TELEFONBUCH</span>
          <Tooltip
            title={<div dangerouslySetInnerHTML={{ __html: tooltipContent }} />}
          >
            <span className={style.tooltip_icon}>
              <MoreInfoIcon></MoreInfoIcon>
            </span>
          </Tooltip>
        </Row>
      </h2>

      <h3>
        Folgenden Telefonnummern sollen einen Telefonbuch-Eintrag erhalten:
      </h3>
      <div className={style.radio_options_wrapper}>
        <Radio.Group
          className={style.radio_group_wrapper}
          onChange={(e) => {
            handleRadioButtonSelect(e.target.value);
          }}
          value={phoneBookData.phoneBookNumberEntryOption}
        >
          <Row
            justify={'start'}
            gutter={[0, 12]}
            className={style.radio_wrapper}
          >
            <Col span={24}>
              <Radio value={0}>Kein Eintrag im Telefonbuch</Radio>
            </Col>
            <Col span={24}>
              <Radio value={1}>Alle Nummern in das Telefonbuch aufnehmen</Radio>
            </Col>
            <Col span={24}>
              <Radio value={2}>
                Nur meine erste Telefonnummer in das Telefonbuch aufnehmen
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </div>
      {phoneBookData.phoneBookNumberEntryOption !== 0 && (
        <>
          <Divider />
          <Row className={style.section_wrapper} gutter={[0, 24]}>
            <Col xs={24} sm={12}>
              <Checkbox
                className="custom_style_checkbox"
                checked={
                  phoneBookData.phoneBookEntry && phoneBookData.phoneBookEntry
                }
                onChange={(e) =>
                  handleCheckboxChange('phoneBookEntry', e.target.checked)
                }
              >
                <p className="package_speed ">Eintrag in Das Telefonbuch</p>
              </Checkbox>
            </Col>
            <Col xs={24} sm={12}>
              <Checkbox
                className="custom_style_checkbox"
                checked={
                  phoneBookData.phoneBookAddress &&
                  phoneBookData.phoneBookAddress
                }
                onChange={(e) =>
                  handleCheckboxChange('phoneBookAddress', e.target.checked)
                }
              >
                <p className="package_speed">Binde meine Adresse mit ein</p>
              </Checkbox>
            </Col>
          </Row>
          <Row className={style.section_wrapper} gutter={[0, 24]}>
            <Col xs={24} sm={12}>
              <Checkbox
                className="custom_style_checkbox"
                checked={
                  phoneBookData.phoneBookOnlineEntry &&
                  phoneBookData.phoneBookOnlineEntry
                }
                onChange={(e) =>
                  handleCheckboxChange('phoneBookOnlineEntry', e.target.checked)
                }
              >
                <p className="package_speed">
                  Eintrag in die Online-Variante in Das Telefonbuch
                </p>
              </Checkbox>
            </Col>
            <Col xs={24} sm={12}>
              <Checkbox
                className="custom_style_checkbox"
                checked={
                  phoneBookData.phoneBookInverseSearch &&
                  phoneBookData.phoneBookInverseSearch
                }
                onChange={(e) =>
                  handleCheckboxChange(
                    'phoneBookInverseSearch',
                    e.target.checked
                  )
                }
              >
                <p className="package_speed">
                  Erlaube, dass ich in einer Rückwärtssuche gefunden werden kann
                </p>
              </Checkbox>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default EntryPhoneBook;
