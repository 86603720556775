import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isMobile: boolean;
  showMobileBasket: boolean;
}

const mobileSlice = createSlice({
  name: 'mobile',
  initialState: {
    isMobile: false,
    showMobileBasket: false
  } as InitialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setShowMobileBasket: (state, action) => {
      state.showMobileBasket = action.payload;
    }
  }
});

export const { setIsMobile, setShowMobileBasket } = mobileSlice.actions;

export const isMobile = (state: { mobile: InitialState }) =>
  state.mobile.isMobile;

export const showMobileBasket = (state: { mobile: InitialState }) =>
  state.mobile.showMobileBasket;

export default mobileSlice.reducer;
