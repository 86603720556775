export const SALES_CHANNELS = [
  { label: 'Post/Fax', value: 'Mail/Post' },
  { label: 'Kundenportal', value: 'Online' },
  { label: 'Externer Vertrieb', value: 'Externer Vertrieb' },
  { label: 'E-Mail/Ticket', value: 'Ticket' },
  {
    label: 'Externer Vertrieb (Kundenportal)',
    value: 'EV KP'
  }
];
