import { useSelector } from 'react-redux';

import {
  selectedPaymentData,
  selectedPersonalInfo,
  selectedShippingData,
  selectedInvoiceData,
  selectedPropertyOwnerData,
  selectedContactPersonData
} from 'store/slices/selectedDataSlice';

import { RootState } from 'store/store';

import { generateUserInfo } from './utils';

import { Row, Col } from 'antd';

import style from './style.module.css';
import { EQUAL_TO_CUSTOMER_MESSAGE } from 'constants/Constants';

const PaymentAndUserInfo = () => {
  const { selectedPaymentOption } = useSelector(
    (state: RootState) => state.iban
  );

  const {
    iban,
    bic,
    credit_institution,
    equalToCustomer,
    account_owner_title,
    account_owner_name,
    account_owner_last_name,
    account_owner_company
  } = useSelector(selectedPaymentData);

  const { title, first_name, last_name, company } =
    useSelector(selectedPersonalInfo);
  const shippingData = useSelector(selectedShippingData);
  const invoiceData = useSelector(selectedInvoiceData);
  const propertyOwnerData = useSelector(selectedPropertyOwnerData);
  const contactPersonData = useSelector(selectedContactPersonData);

  const formatIbanNumber = () => {
    const visiblePart = iban.substring(0, 4);
    const hiddenPart = iban.substring(4, iban.length - 4).replace(/./g, '*');
    const lastFourVisible = iban.substring(iban.length - 4);

    return `${visiblePart}${hiddenPart}${lastFourVisible}`;
  };

  const checkAddressData = (data: {
    street: string;
    house_number: string;
    house_suffix: string;
    post_code: string;
    location: string;
  }): boolean => {
    return (
      !!data.street ||
      !!data.house_number ||
      !!data.house_suffix ||
      !!data.post_code ||
      !!data.location
    );
  };

  const generateAddressString = (data: {
    street: string;
    house_number: string;
    house_suffix: string;
    post_code: string;
    location: string;
    email?: string;
    phone_number?: string;
  }): string => {
    const addressParts = [
      `${data.street}${data.house_number && ` ${data.house_number}`}${data.house_suffix ? ' ' + data.house_suffix : ''}`,
      (data.street || data.house_number || data.house_suffix) &&
      (data.post_code || data.location)
        ? ','
        : '',
      ` ${data.post_code} ${data.location}`,
      checkAddressData(data) ? '\n' : '',
      data.email && `${data.email}\n`,
      data.phone_number || ''
    ];

    return addressParts.join('');
  };

  const ibanOwner = equalToCustomer
    ? `${title} ${first_name} ${last_name}`
    : `${account_owner_title} ${account_owner_name} ${account_owner_last_name}`;

  const ibanOwnerCompany = equalToCustomer ? company : account_owner_company;

  const shippingInfoCompany = shippingData.company;
  const shippingInfoPersonalData = generateUserInfo(shippingData);
  const shippingInfoAddress = `${shippingData.street} ${shippingData.house_number} ${shippingData.house_suffix ?? ''}, ${shippingData.post_code} ${shippingData.location}`;

  const invoiceInfoCompany = invoiceData.company;
  const invoiceInfoPersonalData = generateUserInfo(invoiceData);
  const invoiceInfoAddress = `${invoiceData.street} ${invoiceData.house_number} ${invoiceData.house_suffix ?? ''}, ${invoiceData.post_code} ${invoiceData.location}`;
  const invoiceInfoEmail = invoiceData.email;

  const propertyOwnerCompany = propertyOwnerData.company;
  const propertyOwnerPersonalData = generateUserInfo(propertyOwnerData);
  const propertyOwnerAddress = generateAddressString(propertyOwnerData);

  const contactPersonInfo = contactPersonData.equalToCustomer
    ? `Mit Auftraggeber identisch`
    : generateUserInfo(contactPersonData) +
      `\n ${contactPersonData.email && `${contactPersonData.email}\n`} ${contactPersonData.phone_number}`;

  return (
    <Row>
      <Col span={24}>
        {selectedPaymentOption === 'banküberweisung' ? (
          <Row className={style.mobile_margin_l}>
            <Col span={12}>
              <Row gutter={[0, 4]}>
                <Col span={24} className={style.info_title}>
                  Zahlung per
                </Col>
                <Col span={24} className={style.info_text}>
                  <p className={style.payment_option_text}>
                    {selectedPaymentOption}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <Row className={style.mobile_margin_l}>
              <Col xs={24} sm={12}>
                <Row>
                  <Col span={24} className={style.info_title}>
                    IBAN
                  </Col>
                  <Col span={24} className={style.info_text}>
                    {formatIbanNumber()}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row>
                  <Col span={24} className={style.info_title}>
                    BIC
                  </Col>
                  <Col span={24} className={style.info_text}>
                    {bic}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={style.mobile_margin_l}>
              <Col xs={24} sm={12}>
                <Row gutter={[0, 4]}>
                  <Col span={24} className={style.info_title}>
                    Kontoinhaber
                  </Col>
                  {ibanOwnerCompany && (
                    <Col span={24} className={style.info_text}>
                      {ibanOwnerCompany}
                    </Col>
                  )}
                  {ibanOwner && (
                    <Col span={24} className={style.info_text}>
                      {ibanOwner}
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row gutter={[0, 4]}>
                  <Col span={24} className={style.info_title}>
                    Kreditinstitut
                  </Col>
                  <Col span={24} className={style.info_text}>
                    {credit_institution}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Row className={style.mobile_margin_l}>
          <Col xs={24} sm={12}>
            <Row gutter={[0, 4]}>
              <Col span={24} className={style.info_title}>
                Versandinformationen
              </Col>
              {shippingData.equalToCustomer ? (
                <Col span={24} className={style.info_text}>
                  {EQUAL_TO_CUSTOMER_MESSAGE}
                </Col>
              ) : (
                <>
                  {shippingInfoCompany && (
                    <Col span={24} className={style.info_text}>
                      {shippingInfoCompany}
                    </Col>
                  )}
                  {shippingInfoPersonalData && (
                    <Col span={24} className={style.info_text}>
                      {shippingInfoPersonalData}
                    </Col>
                  )}
                  {shippingInfoAddress && (
                    <Col span={24} className={style.info_text}>
                      {shippingInfoAddress}
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Col>

          <Col xs={24} sm={12}>
            <Row gutter={[0, 4]}>
              <Col span={24} className={style.info_title}>
                Rechnungsadresse
              </Col>
              {invoiceData.equalToCustomer ? (
                <Col span={24} className={style.info_text}>
                  {EQUAL_TO_CUSTOMER_MESSAGE}
                </Col>
              ) : (
                <>
                  {invoiceInfoCompany && (
                    <Col span={24} className={style.info_text}>
                      {invoiceInfoCompany}
                    </Col>
                  )}
                  {invoiceInfoPersonalData && (
                    <Col span={24} className={style.info_text}>
                      {invoiceInfoPersonalData}
                    </Col>
                  )}
                  {invoiceInfoAddress && (
                    <Col span={24} className={style.info_text}>
                      {invoiceInfoAddress}
                    </Col>
                  )}
                  {invoiceInfoEmail && (
                    <Col span={24} className={style.info_text}>
                      {invoiceInfoEmail}
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Col>
        </Row>

        <Row className={style.mobile_margin_l}>
          <Col xs={24} sm={12}>
            <Row gutter={[0, 4]}>
              <Col span={24} className={style.info_title}>
                Grundstückseigentümer
              </Col>
              {propertyOwnerData.equalToCustomer ? (
                <Col span={24} className={style.info_text}>
                  {EQUAL_TO_CUSTOMER_MESSAGE}
                </Col>
              ) : (
                <>
                  {propertyOwnerCompany && (
                    <Col span={24} className={style.info_text}>
                      {propertyOwnerCompany}
                    </Col>
                  )}
                  {propertyOwnerPersonalData && (
                    <Col span={24} className={style.info_text}>
                      {propertyOwnerPersonalData}
                    </Col>
                  )}
                  {propertyOwnerAddress && (
                    <Col span={24} className={style.info_text}>
                      {propertyOwnerAddress}
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Col>

          <Col xs={24} sm={12}>
            <Row gutter={[0, 4]}>
              <Col span={24} className={style.info_title}>
                Kontaktperson für Hausbegehung
              </Col>
              <Col span={24} className={style.info_text}>
                {contactPersonInfo}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PaymentAndUserInfo;
