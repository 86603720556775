import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../api-config';

interface ApiState {
  data: any | null;
  loading: boolean;
  error: string | null;
}

interface PostDataWithQueryPayload {
  payloadData: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null
};

export const postFinalSubmit = createAsyncThunk(
  'api/postDataWithQuery',
  async (payloadData: PostDataWithQueryPayload) => {
    const response = await axios.post(`${API_URL}/orders/`, payloadData);
    return response.data;
  }
);

const finalSubmitSlice = createSlice({
  name: 'finalSubmit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the pending state
    builder.addCase(postFinalSubmit.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    // Handle the fulfilled state
    builder.addCase(
      postFinalSubmit.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
  }
});

export default finalSubmitSlice.reducer;
