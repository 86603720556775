import SideBar from 'components/SideBar';
import Steps from 'components/Steps';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <SideBar />
      <Steps />
    </div>
  );
};

export default App;
