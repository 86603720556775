import axios from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Iban } from 'types/bundle';
import { API_URL } from '../../api-config';

interface InitialState {
  data: Iban;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  selectedPaymentOption: string;
  paymentRequired: boolean;
  showPaymentOption: boolean;
}

export const getIbanData = createAsyncThunk<Iban, string>(
  'iban/getIbanData',
  async (ibanNumber) => {
    const response = await axios.get<any>(
      `${API_URL}/bank-details/${ibanNumber}`
    );

    return response.data;
  }
);

const ibanSlice = createSlice({
  name: 'iban',
  initialState: {
    data: {},
    status: 'idle',
    error: '',
    selectedPaymentOption: 'sepa-lastschrifteinzug',
    paymentRequired: true,
    showPaymentOption: false
  } as InitialState,
  reducers: {
    setSelectedPaymentOption: (state, action) => {
      state.selectedPaymentOption = action.payload;
    },
    setPaymentRequired: (state, action) => {
      state.paymentRequired = action.payload;
    },
    setShowAdditionalPayment: (state, action) => {
      state.showPaymentOption = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIbanData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getIbanData.fulfilled, (state, action: PayloadAction<Iban>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getIbanData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An error occurred';
      });
  }
});

export const {
  setSelectedPaymentOption,
  setPaymentRequired,
  setShowAdditionalPayment
} = ibanSlice.actions;

export default ibanSlice.reducer;
