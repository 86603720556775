import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  monthlyPrice: { month: number | null; price: number | null }[];
  oneTimePrice: number;
}

const pricesSlice = createSlice({
  name: 'prices',
  initialState: {
    monthlyPrice: [],
    oneTimePrice: 0
  } as InitialState,
  reducers: {
    setMonthlyPrice: (state, action) => {
      state.monthlyPrice = action.payload;
    },
    setOneTimePrice: (state, action) => {
      state.oneTimePrice = action.payload;
    }
  }
});

export const { setMonthlyPrice, setOneTimePrice } = pricesSlice.actions;

export const monthlyPrice = (state: { prices: InitialState }) =>
  state.prices.monthlyPrice;
export const oneTimePrice = (state: { prices: InitialState }) =>
  state.prices.oneTimePrice;

export default pricesSlice.reducer;
