import { createSlice } from '@reduxjs/toolkit';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';

import { Products } from 'types/bundle';

export type ServiceType = {
  service: Products;
  amount: number;
};

interface InitialState {
  selectedProducts: ServiceType[];
}

const shippingPriceProductId = SHIPPING_PRODUCT_ID;

const selectedServicesSlice = createSlice({
  name: 'selectedServices',
  initialState: {
    selectedProducts: []
  } as InitialState,

  reducers: {
    setSelectedServices: (state, action) => {
      state.selectedProducts = action.payload;
    },
    removeAdditionalProducts: (state) => {
      const mainProducts = state.selectedProducts.filter(
        (services: ServiceType) =>
          !services?.service?.depends_on_products ||
          services?.service?.depends_on_products?.length === 0
      );
      const additionalProducts = state.selectedProducts.filter(
        (services: ServiceType) =>
          services?.service?.depends_on_products &&
          services?.service?.depends_on_products?.length > 0
      );

      const validMainProductIds = new Set(
        mainProducts.map(
          (services: ServiceType) => services?.service?.productID
        )
      );

      const filteredAdditionalProducts = additionalProducts.filter(
        (services: ServiceType) =>
          services?.service?.depends_on_products?.some((mainProductId) =>
            validMainProductIds.has(mainProductId)
          )
      );

      state.selectedProducts = [...mainProducts, ...filteredAdditionalProducts];
    },
    addShippingPrice: (state, action) => {
      const shippingProduct = action.payload;
      const isShippingPriceAlreadyIncluded = state.selectedProducts.some(
        (services: ServiceType) =>
          services?.service?.productID === shippingPriceProductId
      );

      if (!isShippingPriceAlreadyIncluded) {
        state.selectedProducts.push(shippingProduct);
      }
    },
    removeShippingPrice: (state) => {
      const isShippingProductsLeft = state.selectedProducts.some(
        (services: ServiceType) =>
          services?.service?.mandatory_products[0] === shippingPriceProductId
      );

      if (!isShippingProductsLeft) {
        state.selectedProducts = state.selectedProducts.filter(
          (services: ServiceType) =>
            services?.service?.productID !== shippingPriceProductId
        );
      }
    }
  }
});

export const {
  setSelectedServices,
  addShippingPrice,
  removeShippingPrice,
  removeAdditionalProducts
} = selectedServicesSlice.actions;

export default selectedServicesSlice.reducer;
