import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  errorPlace: string;
  errorMessage: string;
}

const errorSlice = createSlice({
  name: 'errors',
  initialState: {
    errorPlace: '',
    errorMessage: ''
  } as InitialState,
  reducers: {
    setErrorPlace: (state, action) => {
      state.errorPlace = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setErrorPlace, setErrorMessage } = errorSlice.actions;

export const errorPlace = (state: { errors: InitialState }) =>
  state.errors.errorPlace;
export const errorMessage = (state: { errors: InitialState }) =>
  state.errors.errorMessage;

export default errorSlice.reducer;
