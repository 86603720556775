import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setPersonalInfo,
  selectedPersonalInfo
} from 'store/slices/selectedDataSlice';

import { PersonalInfo } from 'types/bundle';

import { Col, Row } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';

import style from './style.module.css';
import { getAddressFromQueryParameters } from './utils';

const InstallationAddress = () => {
  const dispatch = useDispatch();

  const personalInfo = useSelector(selectedPersonalInfo);
  const [userData, setUserData] = useState<PersonalInfo>(personalInfo);

  const { street, houseNumber, houseNumberSuffix, postcode, location } =
    getAddressFromQueryParameters();

  useEffect(() => {
    setUserData({
      ...userData,
      street,
      house_number: houseNumber,
      house_suffix: houseNumberSuffix,
      post_code: postcode,
      location
    });

    dispatch(
      setPersonalInfo({
        ...personalInfo,
        street,
        house_number: houseNumber,
        house_suffix: houseNumberSuffix,
        post_code: postcode,
        location
      })
    );
  }, []);

  return (
    <div className={style.installation_address_container}>
      <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
        <Col span={16} md={12}>
          <FloatingLabelInput
            label="Straße"
            value={userData.street}
            name="street"
            disabled
          />
        </Col>
        <Col span={8} md={6}>
          <FloatingLabelInput
            label="Hausnummer"
            value={userData.house_number}
            name="house_number"
            disabled
          />
        </Col>
        <Col span={24} md={6}>
          {userData.house_suffix && (
            <FloatingLabelInput
              label="Hausnummerzusatz (optional)"
              value={userData.house_suffix}
              name="house_suffix"
              disabled
            />
          )}
        </Col>
      </Row>

      <Row gutter={12} className="margin_bottom_m">
        <Col span={8} md={12}>
          <FloatingLabelInput
            label="Postleitzahl"
            value={userData.post_code}
            name="post_code"
            disabled
          />
        </Col>
        <Col span={16} md={12}>
          <FloatingLabelInput
            label="Ort"
            value={userData.location}
            name="location"
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default InstallationAddress;
