import { FloatingLabelInputProps } from './interface';

import { Input } from 'antd';

import './style.css';

const FloatingLabelInput = ({
  label,
  value,
  name,
  disabled,
  onChangeHandler,
  onBlur,
  errorStatus,
  errorMessage,
  type,
  onKeyDown
}: FloatingLabelInputProps) => {
  return (
    <div className="floating-label-input" id="floating-label-input">
      <Input
        value={value}
        type={type}
        onChange={(event) => onChangeHandler(event)}
        placeholder=""
        name={name}
        disabled={disabled}
        onBlur={onBlur && onBlur}
        status={errorStatus}
        onKeyDown={onKeyDown ?? null}
      />
      <label htmlFor="floating-label-input">{label}</label>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default FloatingLabelInput;
