export const getAddressFromQueryParameters = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  const street = queryParameters.get('street') || '';
  const houseNumber = queryParameters.get('street_no') || '';
  const houseNumberSuffix = queryParameters.get('street_no_suffix') || '';
  const postcode = queryParameters.get('postcode') || '';
  const location = queryParameters.get('town') || '';

  return { street, houseNumber, houseNumberSuffix, postcode, location };
};

export const shortenAddress = (
  address: string,
  maxLength: number = 10
): string => {
  if (!address) return '';

  return address.length > maxLength
    ? `${address.substring(0, maxLength)}...`
    : address;
};
