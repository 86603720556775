import { useSelector } from 'react-redux';

import { selectedCharity } from 'store/slices/selectedDataSlice';

import { Row, Col } from 'antd';

import { RootState } from 'store/store';

import CheckIcon from 'assets/images/svg/check_icon.svg';

import style from './style.module.css';

const DonationInfo = () => {
  const selectedCharityForDonation = useSelector(selectedCharity);

  const { data: charityData } = useSelector(
    (state: RootState) => state.donationCharities
  );

  return (
    <Row gutter={[8, 24]}>
      <Col xs={24} xl={10}>
        <Row>
          <Col span={2}>
            <img src={CheckIcon} alt="check_icon" />
          </Col>
          <Col span={22}>
            <span className={style.info_text}>
              Gerne soll MUENET {`${charityData?.donation_value ?? null} €`} an
              den folgenden Verein für meinen Vertragsabschluss spenden.
            </span>
          </Col>
        </Row>
      </Col>

      <Col xs={24} xl={{ span: 10, offset: 2 }}>
        <Row gutter={[0, 4]}>
          <Col span={24} className={style.info_title}>
            Ausgewählte Verein
          </Col>
          <Col span={24} className={style.info_text}>
            {selectedCharityForDonation} Verein
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DonationInfo;
