import { Products } from 'types/bundle';

interface MonthlyProducts {
  product: Products;
  amount: number;
}

export const calculatePriceChanges = (products: MonthlyProducts[]) => {
  const flattenProducts: any = (products: MonthlyProducts[]) => {
    return products.reduce((acc, product) => {
      return acc.concat(
        Array.isArray(product) ? flattenProducts(product) : product
      );
    }, []);
  };

  const flatProducts = flattenProducts(products);

  const totalDuration = 25;
  const priceChanges = [];

  let prevMonthPrice = null;

  for (let month = 0; month < totalDuration; month++) {
    let price = 0;
    const productsByMonth = [];

    for (const product of flatProducts) {
      if (product?.service?.monthly_billing) {
        if (month < product?.service?.offer_duration) {
          if (product.amount) {
            price += product?.service?.price_offer * product.amount;
            productsByMonth.push({
              product: product?.service,
              amount: product?.amount,
              productPrice: product?.service?.price_offer * product.amount
            });
          } else {
            price += product?.service?.price_offer;
            productsByMonth.push({
              product: product?.service,
              amount: product?.amount,
              productPrice: product?.service?.price_offer
            });
          }
        } else {
          if (product.amount) {
            price += product?.service?.price_standard * product.amount;
            productsByMonth.push({
              product: product?.service,
              amount: product?.amount,
              productPrice: product?.service?.price_standard * product.amount
            });
          } else {
            price += product?.service?.price_standard;
            productsByMonth.push({
              product: product?.service,
              amount: product?.amount,
              productPrice: product?.service?.price_standard
            });
          }
        }
      }
    }

    if (price !== prevMonthPrice) {
      priceChanges.push({
        month: month + 1,
        price,
        productsByMonth
      });
    }

    prevMonthPrice = price;
  }

  return priceChanges;
};
