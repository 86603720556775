import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../api-config';

interface ApiState {
  data: any | null;
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

interface PostDataWithQueryPayload {
  payloadData: any;
}

const initialState: ApiState = {
  data: null,
  status: 'idle',
  error: null
};

export const postPdfSubmit = createAsyncThunk(
  'api/postDataWithQuery',
  async (payloadData: PostDataWithQueryPayload) => {
    const response = await axios.post(
      `${API_URL}/api/orders/order-summary-pdf`,
      payloadData
    );
    return response.data;
  }
);

const pdfSubmitSlice = createSlice({
  name: 'pdfSubmit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the pending state
    builder.addCase(postPdfSubmit.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    // Handle the fulfilled state
    builder.addCase(
      postPdfSubmit.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      }
    );
  }
});

export default pdfSubmitSlice.reducer;
