import { createSlice } from '@reduxjs/toolkit';

import {
  Products,
  IptvAdditionalOption,
  ExistingConnectionData,
  AdditionalPhoneNumber,
  PersonalInfo,
  PaymentData,
  ShippingData,
  PropertyOwnerData,
  ContactPersonData,
  InvoiceData,
  ConversationChanel,
  PhoneBook,
  AgentFilesData,
  ClientAddressData
} from 'types/bundle';
import { TermsAndConditionsAgreements } from 'components/Step/OrderSummary/termsAndConditionsValidationSchema';

export interface InitialState {
  packageType: string;
  packageTypeProduct: Products;
  selectedInternet: Products;
  internetAdditionalOptions: Products[] | [];
  selectedRouter: Products;
  selectedIptv: Products;
  iptvAdditionalOption: IptvAdditionalOption;
  selectedTelephony: Products[] | [];
  telephonyAdditionalOption: any;
  selectedExistingConnection: ExistingConnectionData;
  additionalPhoneNumber: AdditionalPhoneNumber;
  addConversationChanel: ConversationChanel;
  faxToMail: boolean;
  phoneBook: PhoneBook;
  personalInfo: PersonalInfo;
  clientAddressData: ClientAddressData;
  paymentData: PaymentData;
  shippingData: ShippingData;
  propertyOwnerData: PropertyOwnerData;
  contactPersonData: ContactPersonData;
  invoiceData: InvoiceData;
  selectedCharity: string;
  shippingProduct: Products;
  termsAndConditionsAgreements: TermsAndConditionsAgreements;
  agentFilesData: AgentFilesData;
}

const selectedDataSlice = createSlice({
  name: 'selectedData',
  initialState: {
    packageType: '',
    packageTypeProduct: {},
    selectedInternet: {},
    internetAdditionalOptions: [],
    selectedRouter: {},
    selectedIptv: {},
    iptvAdditionalOption: {
      additionalOptionSelected: false,
      sticksAmount: 1,
      additionalIptPackage: {}
    },
    selectedTelephony: [],
    telephonyAdditionalOption: {
      additionalOptionSelected: false,
      additionalPackageData: {}
    },
    selectedExistingConnection: {
      cancelMyCurrentPlan: false,
      portMyCurrentPhoneNumber: false,
      previousProvider: '',
      endOfContractTerm: '',
      currentConnectionOwner: {
        equalToCustomer: true,
        title: '',
        name: '',
        lastName: '',
        company: ''
      },
      existingPhoneNumber: [
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: '',
          phoneNumberPrice: 0
        },
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: '',
          phoneNumberPrice: 0
        },
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: '',
          phoneNumberPrice: 0
        }
      ]
    },
    additionalPhoneNumber: {
      additionalNumber: false,
      additionalNumbersAmount: 1,
      additionalPhoneData: {}
    },
    addConversationChanel: {
      addChanel: false,
      chanelAmount: 1,
      chanelProduct: {}
    },
    faxToMail: false,
    phoneBook: {
      phoneBookEntry: false,
      phoneBookOnlineEntry: false,
      phoneBookAddress: false,
      phoneBookInverseSearch: false,
      phoneBookNumberEntryOption: 0
    },
    personalInfo: {
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      birth_date: '',
      phone_number: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },
    clientAddressData: {
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      equalToCustomer: true
    },
    paymentData: {
      iban: '',
      bic: '',
      credit_institution: '',
      account_owner_title: '',
      account_owner_name: '',
      account_owner_last_name: '',
      account_owner_company: '',
      accept_payment_terms: false,
      valid: false,
      equalToCustomer: true,
      manuallyTyped: false
    },
    shippingData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },

    invoiceData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },
    propertyOwnerData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },
    contactPersonData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: ''
    },
    selectedCharity: '',
    shippingProduct: {},
    termsAndConditionsAgreements: {
      agbTermsAgreement: false,
      confirmationDlAndRevokeProductsAgreement: false,
      confirmationSummaryOfServices: false
    },
    agentFilesData: {
      agentId: '',
      uploadedFiles: [],
      salesChannel: ''
    }
  } as InitialState,

  reducers: {
    setPackageType: (state, action) => {
      state.packageType = action.payload;
    },

    setPackageTypeProduct: (state, action) => {
      state.packageTypeProduct = action.payload;
    },

    setSelectedInternet: (state, action) => {
      state.selectedInternet = action.payload;
    },

    setInternetAdditionalOptions: (state, action) => {
      state.internetAdditionalOptions = action.payload;
    },

    setSelectedRouter: (state, action) => {
      state.selectedRouter = action.payload;
    },
    setSelectedIptv: (state, action) => {
      state.selectedIptv = action.payload;
    },

    setIptvAdditionalOption: (state, action) => {
      state.iptvAdditionalOption = action.payload;
    },

    setSelectedTelephony: (state, action) => {
      state.selectedTelephony = action.payload;
    },

    setTelephonyAdditionalOptions: (state, action) => {
      state.telephonyAdditionalOption = action.payload;
    },

    setExistingConnection: (state, action) => {
      state.selectedExistingConnection = action.payload;
    },
    setExistingConnectionOwnerOptions: (state, action) => {
      state.selectedExistingConnection.currentConnectionOwner = action.payload;
    },
    setExistingConnectionPhoneNumbers: (state, action) => {
      state.selectedExistingConnection.existingPhoneNumber = action.payload;
    },
    setAdditionalPhoneNumber: (state, action) => {
      state.additionalPhoneNumber = action.payload;
    },

    setConversationChanel: (state, action) => {
      state.addConversationChanel = action.payload;
    },
    setFaxToMail: (state, action) => {
      state.faxToMail = action.payload;
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    setClientAddressData: (state, action) => {
      state.clientAddressData = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
    setShippingData: (state, action) => {
      state.shippingData = action.payload;
    },
    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
    setPropertyOwnerData: (state, action) => {
      state.propertyOwnerData = action.payload;
    },
    setContactPersonData: (state, action) => {
      state.contactPersonData = action.payload;
    },
    setCharity: (state, action) => {
      state.selectedCharity = action.payload;
    },
    setShippingProduct: (state, action) => {
      state.shippingProduct = action.payload;
    },
    setTermsAndConditionsAgreements: (state, action) => {
      state.termsAndConditionsAgreements = action.payload;
    },
    setPhoneBook: (state, action) => {
      state.phoneBook = action.payload;
    },
    setAgentFilesData: (state, action) => {
      state.agentFilesData = action.payload;
    }
  }
});

export const {
  setPackageType,
  setPackageTypeProduct,
  setSelectedInternet,
  setInternetAdditionalOptions,
  setSelectedRouter,
  setSelectedIptv,
  setIptvAdditionalOption,
  setSelectedTelephony,
  setTelephonyAdditionalOptions,
  setExistingConnection,
  setExistingConnectionOwnerOptions,
  setExistingConnectionPhoneNumbers,
  setAdditionalPhoneNumber,
  setConversationChanel,
  setFaxToMail,
  setPersonalInfo,
  setClientAddressData,
  setPaymentData,
  setShippingData,
  setInvoiceData,
  setPropertyOwnerData,
  setContactPersonData,
  setCharity,
  setShippingProduct,
  setTermsAndConditionsAgreements,
  setPhoneBook,
  setAgentFilesData
} = selectedDataSlice.actions;

export const selectedPackageType = (state: { selectedData: InitialState }) =>
  state.selectedData.packageType;

export const selectedPackageTypeProduct = (state: {
  selectedData: InitialState;
}) => state.selectedData.packageTypeProduct;

export const selectedInternet = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedInternet;

export const selectedAdditionalInternetOptions = (state: {
  selectedData: InitialState;
}) => state.selectedData.internetAdditionalOptions;

export const selectedRouter = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedRouter;

export const selectedIptv = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedIptv;

export const selectedIptvAdditionalOptions = (state: {
  selectedData: InitialState;
}) => state.selectedData.iptvAdditionalOption;

export const selectedTelephony = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedTelephony;

export const telephonyAdditionalOptions = (state: {
  selectedData: InitialState;
}) => state.selectedData.telephonyAdditionalOption;

export const selectedExistingConnection = (state: {
  selectedData: InitialState;
}) => state.selectedData.selectedExistingConnection;

export const selectedAdditionalPhoneNumber = (state: {
  selectedData: InitialState;
}) => state.selectedData.additionalPhoneNumber;

export const selectedConversationChanel = (state: {
  selectedData: InitialState;
}) => state.selectedData.addConversationChanel;

export const selectedFaxToMail = (state: { selectedData: InitialState }) =>
  state.selectedData.faxToMail;

export const selectedPersonalInfo = (state: { selectedData: InitialState }) =>
  state.selectedData.personalInfo;

export const selectedClientAddressData = (state: {
  selectedData: InitialState;
}) => state.selectedData.clientAddressData;

export const selectedPaymentData = (state: { selectedData: InitialState }) =>
  state.selectedData.paymentData;

export const selectedShippingData = (state: { selectedData: InitialState }) =>
  state.selectedData.shippingData;

export const selectedInvoiceData = (state: { selectedData: InitialState }) =>
  state.selectedData.invoiceData;

export const selectedPropertyOwnerData = (state: {
  selectedData: InitialState;
}) => state.selectedData.propertyOwnerData;

export const selectedContactPersonData = (state: {
  selectedData: InitialState;
}) => state.selectedData.contactPersonData;

export const selectedCharity = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedCharity;

export const selectedShippingProduct = (state: {
  selectedData: InitialState;
}) => state.selectedData.shippingProduct;

export const selectedTermsAndConditionsAgreements = (state: {
  selectedData: InitialState;
}) => state.selectedData.termsAndConditionsAgreements;

export const selectedAgentFilesData = (state: { selectedData: InitialState }) =>
  state.selectedData.agentFilesData;

export const selectedPhoneBook = (state: { selectedData: InitialState }) =>
  state.selectedData.phoneBook;

export default selectedDataSlice.reducer;
