import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { postPdfSubmit } from 'store/slices/pdfSubmitSlice';

import { ConfirmBannerPros } from './interface';
import { RootState } from 'store/store';

import style from './style.module.css';

const MUENET_HOME_PAGE = 'https://www.muenet-glasfaser.de/';

const ConfirmBanner = ({ payloadData }: ConfirmBannerPros) => {
  const dispatch = useDispatch();

  const { data } = useSelector((state: RootState) => state.pdfSubmit);

  const blob = new Blob([data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'summary.pdf';

  // const handlePdfDownloadClick = () => {
  //   if (status === 'succeeded' && data) {
  //     window.URL.revokeObjectURL(url);
  //     a.click();
  //   }
  // };

  const handleGoBackOnMuenetWebsite = () => {
    window.location.href = MUENET_HOME_PAGE;
  };

  useEffect(() => {
    dispatch(postPdfSubmit(payloadData) as any);
  }, [dispatch, payloadData]);

  return (
    <div className={style.confirm_container}>
      <h1 className={style.confirm_title}>
        {`Vielen Dank!`} <br />
        Ihre Bestellung wurde erfolgreich versendet.
      </h1>

      <p className={style.check_email_text}>
        Bitte überprüfen Sie Ihr E-Mail-Postfach, wir schicken Ihnen in Kürze
        eine <b>Auftragszusammenfassung PDF</b>.
      </p>

      <div className={style.buttons_wrapper}>
        <Button
          className={style.back_to_site_button}
          onClick={handleGoBackOnMuenetWebsite}
        >
          Zurück zur MUENET-Webseite
        </Button>
        {/* <Button
          className={style.download_pdf_button}
          onClick={handlePdfDownloadClick}
        >
          Zusammenfassung PDF herunterladen
        </Button> */}
      </div>
    </div>
  );
};

export default ConfirmBanner;
